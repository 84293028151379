<po-page-default p-title="{{ literals?.str0065 }}" [p-breadcrumb]="breadcrumb">

  <tgc-quote-data
    [formDataReceived]="formQuoteData"
    (formDataSent)="handleQuoteDataForm($event)">
  </tgc-quote-data>

  <tgc-requested-products
    [formDataReceived]="formRequestedProducts"
    (formDataSent)="handleRequestedProductsForm($event)"
    [quotationItems]="quotationItems">
  </tgc-requested-products>

  <div class="actions-group-buttons po-mt-2 po-mb-2">
    <po-button
      class="po-mr-md-2"
      [p-label]="this.literals?.str0101"
      [p-loading]="isLoading"
      (p-click)="cancel()">
    </po-button>
    <po-button
      p-kind="primary"
      [p-label]="this.literals?.str0095"
      [p-loading]="isLoading"
      [p-disabled]="!isQuoteFormDataValid"
      (p-click)="nextStep()">
    </po-button>
  </div>

</po-page-default>
