import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PoDialogService, PoListViewLiterals, PoModalAction, PoModalComponent, PoNotificationService, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { PurchaseRequestsService } from '../../shared/services/purchase-requests.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Utils } from 'src/app/shared/helper/utils';
import { SupplierService } from '../../shared/services/suppliers.service';
import { DateHandler } from 'src/app/shared/helper/date-handler';
import { SupplierModel } from '../../shared/models/supplier.model';
import { Subscription, interval } from 'rxjs';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';
import { ExecutionHashService } from 'src/app/shared/services/execution-hash.service';

declare var totvstec: any; //-- Variável disponível apenas quando a aplicação é executada dentro do Protheus
@Component({
    selector: 'pgc-supplier-binding',
    templateUrl: './supplier-binding.component.html',
    styleUrls: ['./supplier-binding.component.css'],
    standalone: false
})
export class SupplierBindingComponent implements OnInit {
  @Input() items: Array<any> = [];
  @Input() editQuotation: boolean = false;
  @Output() selectionStatus: EventEmitter<Object> = new EventEmitter(null);
  @ViewChild('supplierUpdateModal', { static: false }) supplierUpdateModal: PoModalComponent;
  @ViewChild('supplierDetailsModal', { static: false }) supplierDetailsModal: PoModalComponent;
  
  selectedSuppliers: Array<any>;
  literals: LiteralsWords = this.literalsService.getLiterals();
  customLiterals: PoListViewLiterals;
  loading: boolean = false;
  hasMoreItems: boolean = false;
  columns: Array<PoTableColumn>;
  actionsColumns: Array<PoTableAction>;
  supplierForm: FormGroup;
  loadingModalUpdate: boolean = false;
  areaCodeFieldSize : number = 0;
  telephoneFieldSize : number = 0;
  emailFieldSize : number = 0;
  formatData: string
  currentSupplier: Object;  
  supplierDetails: SupplierModel;

  // -- Nova interface de atualização de contato
  isNewUpdateContact: boolean = false;
  public intervalClosed: Subscription;

  primaryActionUpdateSupplierModal: PoModalAction = {
    action: () => {
      this.updateContacts();
    },
    label: this.literals?.str0161,
  };

  secondaryActionUpdateSupplierModal: PoModalAction = {
    action: () => {
      this.supplierUpdateModal?.close();
    },
    label: this.literals?.str0092,
  };

  primaryActionSupplierDetailsModal: PoModalAction = {
    action: () => {
      this.supplierDetailsModal?.close();
    },
    label: this.literals?.str0092,
  };

  constructor( 
    private literalsService: LiteralsService, 
    private purchaseRequestsService : PurchaseRequestsService,
    private supplierService: SupplierService,
    public poDialog: PoDialogService,
    private poNotification: PoNotificationService,
    private formBuilder: FormBuilder,
    private utils: Utils,
    private temporaryStorageService: TemporaryStorageService,
    private executionHashService: ExecutionHashService

  ) { }

  ngOnInit(): void {
    this.isNewUpdateContact = sessionStorage['paramNewUpdateContact'] == 'true';
    this.setCustomLiterals();
    this.formatCnpj();
    this.columns = this.purchaseRequestsService.columnsSuppliers();
    this.actionsColumns = this.tableActions();
    this.buildForm();
    this.areaCodeFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_DDD');
    this.telephoneFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_TEL');
    this.emailFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_EMAIL');

    this.items.forEach(item => {
      this.validateSelectedSuppliers(item);
    })
  }

  formatCnpj() {
    const dateHandler = new DateHandler();  
    this.items.forEach(element => {
      element.suppliers.forEach(supplier => {
        let cnpj: string = supplier.cnpj.trim();
        const hasDot = /\./.test(cnpj); //-- Verifica se já foi convertido

        if (supplier.type === 'J' && cnpj.length > 0 && !hasDot) {
          supplier.cnpj = dateHandler.convertCnpj(cnpj);
        }
        if (supplier.type === 'F' && cnpj.length > 0 && !hasDot) {
          supplier.cnpj = dateHandler.convertCpf(cnpj);
        }
      });
    });
  }

  buildForm(): void {
    this.supplierForm = this.formBuilder.group({
      telephone: [''],
      store: [''],
      email: [''],
      areacode: [''],
      suppliercode: [''],
      emails: [],
    });
  }

  // abertura de modal atualização de contatos
  openModalUpdateContacts(supplier): void {
    if (this.isNewUpdateContact) {
      this.loading = true;
      let data = {
        supplierCode: supplier.suppliercode,
        supplierStore: supplier.store,
        executionHash: this.executionHashService.getExecutionHash()
      }
      totvstec.jsToAdvpl("updateContact", JSON.stringify(data));
      this.updateContactClosed(supplier)
    } else {
      supplier.areacode = supplier.areacode.trim();
      supplier.telephone = supplier.telephone.trim();
      supplier.email = supplier.email.trim();
      this.currentSupplier = supplier;
      this.supplierForm.patchValue(supplier);
      this.supplierUpdateModal?.open();
    }
  }

  //-- Aguarda o fechamento da nova interface.
  updateContactClosed(supplier: Object): void {
    sessionStorage.setItem('updateContactClosed', undefined);

    this.intervalClosed = interval(1000).subscribe(() => {
      let updateContactClosed: string | null = sessionStorage.getItem('updateContactClosed');
  
      if (updateContactClosed !== 'undefined') {
        updateContactClosed = JSON.parse(updateContactClosed);

        if (updateContactClosed['executionHash'] === this.executionHashService.getExecutionHash()) {
          if (updateContactClosed['wasSaved']) {
            supplier['email'] = updateContactClosed['email'];
            supplier['emaillist'] = updateContactClosed['emaillist'];
            supplier['areacode'] = updateContactClosed['areacode'];
            supplier['telephone'] = updateContactClosed['telephone'];
          }
          
          this.loading = false;
          this.intervalClosed.unsubscribe();
        }
      }
    });
  }

  // MOdal detalhes do forncedor
  openModalDetails(supplier):void {
    this.supplierDetailsModal.open();
    this.supplierDetails = supplier;
  }

  updateContacts(): void {
    const {email} = this.supplierForm.controls;
    const emailValidated: Object = this.utils.validateEmail(email.value);

    if (!emailValidated['invalid']) {

      this.loadingModalUpdate = true;
      this.supplierService.updateContact(this.supplierForm.value)
      .subscribe(response => {
        this.loadingModalUpdate = false;

        this.currentSupplier['areacode'] = response?.areacode;
        this.currentSupplier['telephone'] = response?.telephone;
        this.currentSupplier['email'] = response?.email;

        this.items.forEach(item => {
          const supplier: Object = item.suppliers.find(
            supplier => supplier['suppliercode'] === this.currentSupplier['suppliercode'] &&
            supplier['store'] === this.currentSupplier['store']
          );
          
          if (supplier) {
            supplier['areacode'] = response?.areacode;
            supplier['telephone'] = response?.telephone;
            supplier['email'] = response?.email;
          }
        });

        this.selectionStatus.emit({'items': this.items})

        this.supplierUpdateModal?.close();
        this.poNotification.success(response.message);
        }, error => {
          this.loadingModalUpdate = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        });
    } else {
      this.poNotification.error(emailValidated['error']);
    }
  }

  setCustomLiterals() {
    this.literals = this.literalsService.getLiterals();
    this.customLiterals = {
      showDetails: this.literals?.str0594,
      hideDetails: this.literals?.str0595,
    };
  }

  tableActions() : Array<PoTableAction>{
    return [
      { action: this.openModalDetails.bind(this),icon: 'po-icon po-icon-eye', label: this.literals.str0123 },
      { action: this.openModalUpdateContacts.bind(this),icon: 'po-icon po-icon-edit', label: this.literals.str0124 },
    ]
  }

  select(supplier: Object, product: Object, selectAll: boolean, unselectAll: boolean): void {

    if (selectAll || unselectAll) {
      product['suppliers'].forEach(element => {
        element['$selected'] = selectAll ? true : false;
      });
    } else {
      const supplierAux: Object = product['suppliers'].find(
        element => element['suppliercode'] === supplier['suppliercode'] &&
        element['store'] === supplier['store']
      );
      
      supplierAux['$selected'] = supplier['$selected']; //-- Atualiza a propriedade $selected do fornecedor do produto
      supplierAux['expirationdate'] = ''; //-- Adiciona propriedade expirationdate
    }

    this.validateSelectedSuppliers(product);
    this.addProposals(product);
  }

  //-- Valida fornecedores selecionados e emite evento
  validateSelectedSuppliers(product: Object): void {
    const selectedSupplier: Object = product['suppliers'].find(element => element['$selected']);
    product['hasSelectedSupplier'] = !!selectedSupplier; //-- Valida se há algum fornecedor selecionado no produto

    const allHaveSuppliers: boolean = this.items.every((element: Object) => element['hasSelectedSupplier']);
    const numberOfSelected: number = this.countSuppliers();

    const emitObject: Object = {
      'allSelected': allHaveSuppliers,
      'numberOfSelected': numberOfSelected,
      'selectedSuppliers': this.selectedSuppliers,
      'items': this.items
    }

    this.selectionStatus.emit(emitObject); //-- Emite evento para o componente supplier-selection
  }

  //-- Conta quantidade de fornecedores selecionados
  countSuppliers(): number {
    const uniqueCodes = new Set<string>();
    this.selectedSuppliers = [];

    for (const item of this.items) {
      for (const supplier of item.suppliers) {
        const supplierCode = supplier['suppliercode'];
        const store = supplier['store'];
        const selected = supplier['$selected'];
        const key = `${supplierCode}${store}`;
                  
        if (selected && !uniqueCodes.has(key)) { // Verificar se está selecionado e se o código já foi adicionado
          uniqueCodes.add(key);
          this.selectedSuppliers.push(supplier); //-- Fornecedores selecionados
        }
      }
    }

    return uniqueCodes.size;
}

  addProposals(product: Object) {
    const selectedSuppliers: Object = product['suppliers'].filter(element => element['$selected']);
    product['proposals'] = selectedSuppliers;
  }

  // Mascara de quantidade 
  toRound(): string {
    let res = this.temporaryStorageService.getValue('fieldsPropertiesSC1');
    if (res) {
        let minFraction = res.items[0].decimal;
        let maxFraction = res.items[0].length;
        return  `1.${minFraction}-${maxFraction}`
    }
  }

  //-- Exibe mensagem de erro do item
  showErrorMessage(item: Object) {
    if (item['errormessage'] && item['errormessage'].trim() !== '') {
      this.poNotification.error(item['errormessage']);
    }
  }
}