/* src/app/features/purchase-requests/shared/components/attach-documents/attach-documents.component.css */
.icon-file-format {
  font-size: 24px;
  color: #B6BDBF;
}
.icon-file-status {
  font-size: 24px;
  color: #097B98;
}
.container-attach {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.align-center {
  justify-content: center;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.upload-box {
  cursor: pointer;
  border: 2px dashed #dadedf;
}
.upload-hover {
  border: 2px dashed #0C9ABE;
}
.upload-opacity {
  opacity: 0.5;
}
.error-upload .po-icon {
  color: #C64840;
}
.error-upload {
  color: #C64840;
}
.success-upload {
  color: #00B283;
}
.success-upload .po-icon {
  color: #00B283;
}
.information-font {
  color: #9da7a9;
  font-size: 12px;
}
.big-upload-icon {
  color: #B6BDBF;
  font-size: 72px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=attach-documents.component.css.map */
