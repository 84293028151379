<div *ngIf="loading">
  <po-loading-overlay></po-loading-overlay>
</div>
<po-list-view 
  [p-items]="items" 
  [p-literals]="customLiterals"
  [p-show-more-disabled]="true"
  (p-show-detail)="showErrorMessage($event)"
  >

  <ng-template p-list-view-content-template let-item>
    <div class="po-row po-mt-2">
      <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
         <po-info [p-label]="literals?.str0082" [p-value]="item?.c1_produto"></po-info>
      </div>
      <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
        <po-info [p-label]="literals?.str0083" [p-value]="item?.c1_descri"></po-info>
      </div>
      <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
        <po-info [p-label]="literals?.str0084" [p-value]="item?.c1_um"></po-info>
      </div>
      <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
        <po-info [p-label]="literals?.str0085" [p-value]="item?.c1_quant | number : toRound()"></po-info>
      </div>

      <div *ngIf="editQuotation" class="po-mt-2 po-xl-4 po-lg-4 po-md-4 po-sm-4">
        <po-input [p-label]="literals?.str0086" [(ngModel)]="item.notesupplier"></po-input>
      </div>

      <div *ngIf="editQuotation" class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-mt-4">
        <span 
          class="po-icon po-icon-exclamation po-mt-4"
          p-tooltip="{{literals.str0616}}"
          p-tooltip-position="right-top"
          >
        </span>
      </div>
    </div>
  </ng-template>

  <!-- Tabela de Fornecedores -->
  <ng-template p-list-view-detail-template let-item >
    <div>
      <po-table 
        p-spacing="small"
        p-sort="true" 
        [p-items]="item.suppliers"  
        [p-columns]="columns"
        [p-selectable]="true"
        p-actions-right="false"
        [p-actions]="actionsColumns"
        (p-selected)="select($event,item)"
        (p-unselected)="select($event, item, false, false)"
        (p-all-selected)="select(null, item, true, false)"
        (p-all-unselected)="select(null, item, false, true)">     
      </po-table>
    </div>
  </ng-template> 
  
 
</po-list-view>

<!-- Modal Detalhes do fornecedor -->

<po-modal #supplierDetailsModal
  p-size="auto"
  p-click-out="false"
  [p-title]="literals?.str0125"
  [p-primary-action]="primaryActionSupplierDetailsModal">
  <po-container>

    <po-info class="po-md-4" [p-label]="literals?.str0073" [p-value]="supplierDetails?.companyname || literals?.str0122"> </po-info>
    <po-info class="po-md-4" [p-label]="literals?.str0156" [p-value]="supplierDetails?.fantasyname || literals?.str0122"> </po-info>

    <po-divider class="po-md-12" ></po-divider>

      <po-info class="po-md-4" [p-label]="literals?.str0153" [p-value]="supplierDetails?.address || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0154" [p-value]="supplierDetails?.district || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0157" [p-value]="supplierDetails?.county || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0158" [p-value]="supplierDetails?.state || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0155" [p-value]="supplierDetails?.zipcode || literals?.str0122"> </po-info>

    <po-divider class="po-md-12" ></po-divider>

      <po-info class="po-md-4" [p-label]="literals?.str0175" [p-value]="supplierDetails?.areacode || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0116" [p-value]="supplierDetails?.telephone || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0115" [p-value]="supplierDetails?.email || literals?.str0122"> </po-info>

  </po-container>
</po-modal>

<!-- Modal Atualização de contato -->
<po-modal #supplierUpdateModal
  p-click-out="false"
  p-size="auto"
  [p-title]="literals?.str0124"
  [p-primary-action]="primaryActionUpdateSupplierModal"
  [p-secondary-action]="secondaryActionUpdateSupplierModal">
  <div *ngIf="loadingModalUpdate">
    <po-loading-overlay></po-loading-overlay>
  </div>

  <form [formGroup]="supplierForm">
    <div class="row">
      <div class="po-xl-2 po-lg-2 ">
        <po-input
          p-auto-focus="true"
          formControlName="areacode"
          p-mask="999"
          [p-label]="literals?.str0175"
          [p-placeholder]="literals?.str0175"
          [p-maxlength]="areaCodeFieldSize">
        </po-input>
      </div>
      <div class="po-xl-4 po-lg-4 ">
        <po-input
            p-auto-focus="true"
            formControlName="telephone"
            [p-label]="literals?.str0116"
            [p-placeholder]="literals?.str0116"
            [p-maxlength]="telephoneFieldSize">
          </po-input>
      </div>
      <div class="po-xl-6 po-lg-6">
        <po-input
          formControlName="email"
          [p-label]="literals?.str0115"
          [p-placeholder]="literals?.str0115"
          [p-maxlength]="emailFieldSize">
        </po-input>
      </div>
    </div>
  </form>

</po-modal>
