import { Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DateHandler } from 'src/app/shared/helper/date-handler';
import { PurchaseRequestsService } from '../../shared/services/purchase-requests.service';
import { PoTableColumn, PoNotificationService, PoModalComponent, PoModalAction, PoNotification, PoDisclaimerGroupRemoveAction, PoTableComponent, PoPageAction  } from '@po-ui/ng-components';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from '../../../../i18n/literals.model';
import { MingleService } from '@totvs/mingle';
import { Utils } from '../../../../shared/helper/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { PurchaseRequest } from '../../shared/models/purchase-requests.model';
import { ItemAppend } from '../../shared/models/item-append.model';
import { PurchaseListService } from '../../shared/services/purchase-list.service';
import { MenuService } from '../../shared/services/menu.service';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
    selector: 'tgc-centralized-purchase',
    templateUrl: './centralized-purchase.component.html',
    styleUrls: ['./centralized-purchase.component.css'],
    standalone: false
})
export class CentralizedPurchaseComponent implements OnInit {

  @Input() loadingSearch: boolean = true;

  @ViewChild('appendModal', { static: true }) appendModal: PoModalComponent;
  @ViewChild('itemsSelectedModal', { static: true }) itemsSelectedModal: PoModalComponent;
  @ViewChild('invalidItemsModal', { static: false }) invalidItemsModal: PoModalComponent;
  @ViewChild('OnlyInvalidItemsModal', { static: false }) OnlyInvalidItemsModal: PoModalComponent;
  @ViewChild('TableSelectedItems', { static: true }) tableSelectedItems: PoTableComponent;
  @ViewChild('TableAllItems', { static: true }) tableAllItems: PoTableComponent;

  openTotal: number;
  disableForm = new BehaviorSubject<boolean>(false);
  loading: boolean = false;
  loadingCart: boolean = false;
  searchOpenForm: FormGroup;
  filterOpenForm: FormGroup;
  advancedFilterOpenForm: FormGroup;
  hasMoreOpenItems: boolean = false;
  hasMoreAppends: boolean = false;
  pageOpenItems: number = 1;
  pageAppends: number = 1;
  pageSize: number = 20;
  debounce: Subject<string> = new Subject<string>();
  openItems1: Array<any>;
  inputSearch: string = '';
  columns: Array<PoTableColumn>;
  requestStatus: number = 0;
  quotationItems1: Array<any> = [];
  appendItem1: PurchaseRequest;
  appendItems1: ItemAppend;
  appendColumns: Array<PoTableColumn>;
  isLoading: boolean = false;
  isLoadingAppendTable: boolean = false;
  isLoadingModalAppends: boolean = false;
  downloadLoading: boolean = false;
  validateDiffProductGroups: boolean = false;
  literals: LiteralsWords = this.literalsService.getLiterals();
  openItemsStartDate: string = '';
  openItemsEndDate: string = '';
  openItemsSearch: string = '';
  openItemsAdvancedQueryFilter: string = '';

  useProductGroup: string = sessionStorage.getItem('useProductGroup');
  
  itemsSelectedColumns1: Array<PoTableColumn>;
  itemsSelected1: Array<any> = [];
  invalidItems: Array<any>;

  columnsInvalidItemsGrid: Array<PoTableColumn>;

  advancedFilterForm: FormGroup;
  branchesColumns = [];
  brancheURL: string = '';
  modelLookupChange: boolean = false;
  cleanLookupModel: boolean = false;
  cleanLookupField: string = '';
  branchesURLParams: string = '';
  branchDescription: string = '';
  itemsSelectedFork: Array<any> = [];
  selectedBranch: string = '';
  branchCode : string = '';
  responsewitherrors: Array<any> = [];

  constructor(
    private purchaseRequestsService: PurchaseRequestsService,
    private poNotification: PoNotificationService,
    private formBuilder: FormBuilder,
    private purchaseListService: PurchaseListService,
    private literalsService: LiteralsService,
    private router: Router,
    private mingleService: MingleService,
    private dateHandler: DateHandler,
    private utils: Utils,
    private menuService: MenuService,
    private temporaryStorageService: TemporaryStorageService
  ) {

    this.searchOpenForm = this.formBuilder.group({
      date: [null],
      search: [''],
    });

    this.advancedFilterOpenForm = this.formBuilder.group({
      c1_num: [null],
      c1_solicit: [null],
      c1_descri: [null],
      b1_grupo: [null],
      bm_desc: [null],
      c1_filent: [null],
      c1_filial: [null],
      c1_cc: [null],
      c1_conta: [null],
      c1_itemcta: [null],
      c1_clvl: [null],
    });

    this.filterOpenForm = this.formBuilder.group({
      searchForm: this.searchOpenForm,
      advancedFilterForm: this.advancedFilterOpenForm,
    });


    this.columnsInvalidItemsGrid = [
      {
        property: 'errormessage',
        label: this.literals?.str0223,
        width: '250px',
      },
      {
        property: 'c1_num',
        label: this.literals?.str0015,
        width: '160px',
      },
      {
        property: 'c1_item',
        label: this.literals?.str0016,
        width: '150px',
      },
      {
        property: 'c1_produto',
        label: this.literals?.str0017,
        width: '200px',
      },
      {
        property: 'c1_descri',
        label: this.literals?.str0018,
        width: '150px',
      },
      {
        property: 'c1_um',
        label: this.literals?.str0020,
        width: '150px',
      },
    ];

    this.branchesColumns = this.purchaseListService.getSearchColumns();
    this.branchesURLParams = `EnterpriseGroup=${this.utils.getEnterpriseGroup()}`
  }

  ngOnInit(): void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);

    this.initialComponent();
   
    this.branchCode = this.utils.getBranchCode() ;

    this.purchaseRequestsService.behaviorRemoveSelectedItem.subscribe(
      (value) => {
        if (value !== undefined) {
          this.handleRemoveItem(value);
          this.inputAnotherTable(value, 'delete')
        }
      }
    );

    this.purchaseRequestsService.behaviorOpenSelectedItemsModal.subscribe(
      (status) => {
        if (status !== undefined) this.handleOpenModalItemsSelected();
      }
    );

    this.purchaseRequestsService.behaviorChangeBranch.subscribe((branch) => {
      if (branch !== undefined) this.initialComponent();
    });
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  primaryActionAppendModal: PoModalAction = {
    action: () => {
      this.appendItems1.items = [];
      this.appendModal.close();
    },
    label: this.literals.str0092,
  };

  primaryActionItemsSelectedModal: PoModalAction = {
    action: () => {
      this.itemsSelected1 = [];
      this.itemsSelectedModal.close();
    },
    label: this.literals.str0092,
  };

  initialComponent(inputSearch = '', advancedFilterForm = ''): void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);

    this.getOpenItems(inputSearch, advancedFilterForm);
    this.columns = this.purchaseRequestsService.returnColumnsTableCentralized();

  }

  //-- Abre o modal de listagem de anexos
  openAttachList(value){
    this.isLoadingAppendTable = true;
    this.appendColumns = this.purchaseRequestsService.returnAppendColumnsTable();
    this.appendColumns.push(
      {
        property: 'append',
        width: '80px',
        type: 'icon',
        label: this.literals?.str0049,
        visible: true,
        icons: [
          {
            action: (value) => {
              this.downloadAttach(value);
            },
            color: 'color-01',
            icon: 'po-icon-download',
            tooltip: this.literals?.str0149,
            value: 'true',
          },
        ],
      }
    )
    this.returnAttachList(value)
    this.appendModal.open()
  }


  getOpenItems(startDate: string = '', endDate: string = '', search: string = '', advancedFilterQuery: string = ''): void {
    this.loading = true;
    this.pageOpenItems = 1;
    this.utils.getBranchCode();
    this.purchaseListService
    .getOpenItems(
      this.pageOpenItems,
      this.pageSize,
      search,
      advancedFilterQuery,
      startDate,
      endDate,
      '',
      true
    )
    .subscribe({
      next: (value: any) => {
        this.loading = false;
        this.processOpenItems(value.items);
        this.hasMoreOpenItems = value.hasNext;
        this.openTotal = value.remainingRecords + this.openItems1.length;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }
  
  private processOpenItems(items: any[]): void {
    items.forEach((element) => {
      if (element.ac9_codent) { // Verifica se element.ac9_codent está definido
        element.ac9_codent = element.ac9_codent.trim() !== '' ? 'true' : 'false';
      } else {
        element.ac9_codent = false
      }
      element['remove'] = 'true';
    });
    this.handleDatesItemsReceived(items);
    this.openItems1 = items;
  }
  
  
  getMoreOpenItems(): void {
    this.loading = true;
    if (this.hasMoreOpenItems) this.pageOpenItems++;
    this.purchaseListService
    .getOpenItems(
      this.pageOpenItems,
      this.pageSize,
      this.openItemsSearch,
      this.openItemsAdvancedQueryFilter,
      this.openItemsStartDate,
      this.openItemsEndDate,
      '',
      true
    )
    .subscribe({
      next: (value) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == ""
            ? (element.ac9_codent = 'false')
            : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.openItems1 = this.openItems1.concat(value.items);
        this.disableForm.next(false);
        this.hasMoreOpenItems = true ? value.hasNext : false;
      },
      error: (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error))
      }
    })
  }


  getMoreAppends(): void {
    this.isLoadingAppendTable = true;
    this.pageAppends ++
    this.purchaseListService
      .getAppendItems(this.pageAppends, this.pageSize, this.appendItem1.requestcode, this.appendItem1.item)
      .subscribe((items: ItemAppend) => {
        this.appendItems1.items = this.appendItems1.items.concat(items.items);
        this.appendItems1.items.forEach((element) => {
          element.append = 'true';
        });
        this.hasMoreAppends = items.hasNext;
        this.isLoadingAppendTable = false;
      },
      (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error));
      });
  }

  handleDatesItemsReceived(items: Array<any>) {
    console.log(items)
    
    let dateHandler = new DateHandler();
    items.map(
      (items) =>
        (items.c1_datprf = dateHandler.fromProtheusToPOUI(items.c1_datprf))
    );
    
    //Trata campos tipo data no browser.
    let structBrowserSC1 = this.utils.getBrowserFields('structSC1', 'SC1');
    const dateFields = structBrowserSC1.filter((field)=> field.type == 'D')

    if (items != undefined){
      if(dateFields != undefined && dateFields.length > 0){
        dateFields.forEach((field)=>{
          let fieldName = field.field.toLowerCase();
          if (fieldName != 'c1_datprf'){
            items?.map(value =>
              value[fieldName] = this.dateHandler.fromProtheusDateToStringPoUi(value[fieldName]));
          }
        });
      }
    }
    
  }

  convertToProtheusDate(startDate: string, endDate: string): Array<string> {
    let dateHandler = new DateHandler();
    const startDateProtheus = dateHandler.convertToProtheusDate(
      dateHandler.fromPOUIDatepickerToDate(startDate)
    );
    const endDateProtheus = dateHandler.convertToProtheusDate(
      dateHandler.fromPOUIDatepickerToDate(endDate)
    );
    return [startDateProtheus, endDateProtheus];
  }

  handleChangeInputDateFilter(searchForm: SearchFilter): void {
    this.clearHasMoreFilterParameters();
    this.filterOpenForm.controls.searchForm.setValue(searchForm);
    this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString());
    this.filterOpenForm.controls['searchForm'].value.date.start ? (this.filterOpenForm.controls['searchForm'].value.date.start = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[0]) : null;
    this.filterOpenForm.controls['searchForm'].value.date.end ? (this.filterOpenForm.controls['searchForm'].value.date.end = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[1]) : null;
    this.generateFilterQuery(this.filterOpenForm);

  }

  handleInputSearchFilter(inputSearch: string) {
    this.clearHasMoreFilterParameters();
    this.filterOpenForm.controls['searchForm'].value.search = inputSearch;
    this.generateFilterQuery(this.filterOpenForm);
  }

  handleAdvancedFilterForm(advancedFilterForm: any): void {
    this.clearHasMoreFilterParameters();
    this.advancedFilterOpenForm.patchValue(advancedFilterForm.value);
    this.generateFilterQuery(this.filterOpenForm);
  }

  generateFilterQuery(filterForm: FormGroup): void {
    let advancedFilterQuery: string = '';
    let date = filterForm?.controls?.searchForm?.value?.date;
    let search = filterForm?.controls?.searchForm?.value?.search?.toUpperCase();
    const array: Array<any> = Object.keys(filterForm?.controls?.advancedFilterForm?.value).map((key) => [key, filterForm?.controls?.advancedFilterForm?.value[key]]);
    const newArray = array.filter((element) => element[1] !== null && element[1] !== '');

    newArray?.forEach((element) => {
      advancedFilterQuery =
        advancedFilterQuery +
        `(${element[0]}, '${
          element[0] !== 'c1_solicit' ? element[1].toUpperCase() : element[1]
        }') AND contains`;
    });

    advancedFilterQuery = advancedFilterQuery.substring(
      0,
      advancedFilterQuery.length - 12
    );
    this.generateHasMoreFilterParameters(
      date?.start,
      date?.end,
      search,
      advancedFilterQuery
    );
    this.getOpenItems(date?.start, date?.end, search, advancedFilterQuery);

  }

  generateHasMoreFilterParameters(startDate,endDate,search,advancedFilterQuery): void {
    this.openItemsStartDate = startDate;
    this.openItemsEndDate = endDate;
    this.openItemsSearch = search;
    this.openItemsAdvancedQueryFilter = advancedFilterQuery;
  }

  clearHasMoreFilterParameters(): void {
        this.openItemsStartDate = '';
        this.openItemsEndDate = '';
        this.openItemsSearch = '';
        this.openItemsAdvancedQueryFilter = '';
  }

  handleChangeInputAdvancedFilter(event: PoDisclaimerGroupRemoveAction): void {
    this.advancedFilterOpenForm.controls[event?.removedDisclaimer?.property].reset();
    this.generateFilterQuery(this.filterOpenForm);
  }

  handleSelectItem(item: any): void {
    let isDuplicated = this.quotationItems1.some((element) => JSON.stringify(item) === JSON.stringify(element));
    if (!isDuplicated) {
      this.quotationItems1.push(item);
      this.inputAnotherTable(item, 'new')
    } else {
      this.poNotification.warning(this.literals?.str0412);
      let index = this.openItems1.indexOf(item);
      this.openItems1.splice(index, 1);
    }
  }

  handleRemoveItem(item: any): void {
    this.handleUnselectItemCP(item);
  }

  handleUnselectItemCP(item: any): void {
    this.loadingCart = true;

    const unselectedItem = this.quotationItems1.find(
      (element) => element['c1_filent'] === item['c1_filent'] &&
                   element['c1_filial'] === item['c1_filial'] &&
                   element['c1_num'] === item['c1_num'] &&
                   element['c1_produto'] === item['c1_produto'] &&
                   element['c1_item'] === item['c1_item'] &&
                   element['c1_itemgrd'] === item['c1_itemgrd']
    )

    const index = this.quotationItems1.indexOf(unselectedItem);
      if (index >= 0) {
        this.quotationItems1.splice(index, 1);
        this.itemsSelected1 = [...this.quotationItems1]
        this.loadingCart= false;
        if (this.openItems1) {
          const key = `${item?.c1_filial}${item?.c1_num}${item?.c1_produto}${item?.c1_item}${item?.c1_itemgrd}`;
          this.loadingCart = false;
  
          const openIndex = this.openItems1.findIndex(element => {
            const elementKey = `${element?.c1_filial}${element?.c1_num}${element?.c1_produto}${element?.c1_item}${element?.c1_itemgrd}`;

            return elementKey === key;  
          });
    
          if (openIndex !== -1) {
            this.openItems1[openIndex].$selected = false;  
          }
        }
      }  
      this.loadingCart = false;
  }

  handleSelectAllItems(items): void {
    let selectedItems: Array<PoTableColumn> = [];
    this.quotationItems1 = [];
    items?.forEach((element) => {
      this.quotationItems1.push(element?.$selected === false);
      selectedItems.push(element);
    });
    this.quotationItems1 = selectedItems;
    this.purchaseRequestsService.handleNotificationsNumber(this.quotationItems1.length);
    this.adjustItemsTable(items, 'new');
  }

  handleUnselectAllItems(items): void {
    this.quotationItems1 = [];
    this.purchaseRequestsService.handleNotificationsNumber(this.quotationItems1.length);
    this.adjustItemsTable(items, 'delete');
  }

  startCentralize(): void {
    if (this.branchCode == "" || this.branchCode == null){
      this.poNotification.warning(this.literals?.str0645);
    } 
    else {
      this.quotationItems1.forEach((element) => {
        element.requestrecno = 0;
        element.centralizedpurchase = true;
        element.selectedBranch = this.branchCode;
        element.beaggroup = false;
        if ( element.hasOwnProperty('c1_scori') ) {
          element.c1_scori = '';   
        }
      });
      this.loading = true;
      this.purchaseRequestsService.agglutinateQuotations(this.quotationItems1)
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.temporaryStorageService.setValue('quotationItems', response);
          let quotationItems = response;
          if (response.items.length === 0 && response.invaliditems.length > 0) {
            this.invalidItems = quotationItems.invaliditems;
            this.invalidItems.forEach(element => {
              element.c1_datprf = this.dateHandler.convertStringToProtheusDate(element.c1_datprf);
            });
            this.showNotificationOnlyInvalid();
          }
          else if (response.invaliditems.length > 0 && response.items.length > 0 ) {
            this.invalidItems = quotationItems.invaliditems;
            this.invalidItems.forEach((element) => {
              element.c1_datprf = this.dateHandler.convertStringToProtheusDate(
                element.c1_datprf
              );
            });
            this.responsewitherrors = quotationItems;
            this.invalidItemsModal.open();
          } 
          else {
            this.centralizedchangeBranchItems(quotationItems);
          }
        },
        error: (error) => {
          this.poNotification.error(this.utils.getErrorMessage(error));
        }
      })  
    } 
  }


  showNotificationOnlyInvalid() {
    const poNotification: PoNotification = {
      message: this.literals.str0230,
      actionLabel: this.literals.str0167,
      action: () => this.OnlyInvalidItemsModal.open(),
    };
    this.poNotification.warning(poNotification);
  }

  downloadPDF(pdf): void {
    const linkSource = `data:application/pdf;base64,${pdf.file}`;
    const downloadLink = document.createElement('a');
    const fileName = `${pdf.name}`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  handleRemoveAllFilters(): void {
    this.getOpenItems('', '', '', '');

  }

  handleOpenModalItemsSelected(): void {
    this.loadingCart = true;
    this.itemsSelectedModal.open();
    this.itemsSelectedColumns1 = this.purchaseRequestsService.returnItemsSelectedColumnsTable();
    this.itemsSelectedColumns1.push(
      {
        property: 'ac9_codent',//'ac9_codent',
        width: '80px',
        type: 'icon',
        label: this.literals?.str0023,
        visible: true,
        icons: [
          {
            action: (value) => {
              this.openAttachList(value);
            },
            color: 'color-01',
            icon: 'po-icon-attach',
            tooltip: this.literals?.str0050,
            value: 'true',
          },
        ],
      }
    )
    this.itemsSelected1 = [...this.quotationItems1];
    this.loadingCart = false;
  }

  showInvalidItemsQuotation(): void {
    this.invalidItemsModal.open();
  }

  primaryActionInvalidItemsModal: PoModalAction = {
    action: () => {
      this.menuService.loading(true);
      this.centralizedchangeBranchItems(this.responsewitherrors);
    },
    label: this.literals?.str0099,
  };

  SecundaryActionInvalidItemsModal: PoModalAction = {
    action: () => {
      this.invalidItemsModal.close();
    },
    label: this.literals?.str0100,
  };

  primaryOnlyInvalidItemsModal: PoModalAction = {
    action: () => {
      this.OnlyInvalidItemsModal.close();
    },
    label: this.literals?.str0092,
  };

  //-- Serviço que busca a listagem de anexos de uma cotação.
  returnAttachList(value){

      this.appendItem1 = value;
      this.pageAppends = 1;
      if (this.appendItem1 !== undefined) {
       this.purchaseListService
        .getAppendItems(1, this.pageSize, value.c1_num, value.c1_item)
        .subscribe((items: ItemAppend) => {
          this.isLoadingAppendTable = false;
          this.appendItems1 = items;
          this.hasMoreAppends = items.hasNext;
          this.appendItems1.items.forEach((element) => {
            element.append = 'true';
          });
          },
          (error) => {
            this.isLoadingAppendTable = false;
            this.poNotification.error(this.utils.getErrorMessage(error));
          });
      }
  }

  downloadAttach(value){
    this.downloadLoading = true;
    if (value !== undefined) {
      this.purchaseListService.getPDF(value.id)
      .subscribe({
        next: (value) => {
          this.downloadPDF(value);
          this.downloadLoading = false;
        },
        error: (error) => {
          this.downloadLoading = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        }
      })
    }
  }

  /***** inputAnotherTable 
  Insere os dados na tabela centralizadora (lado direito), de acordo com a seleção dos itens na tabela a esquerda - que mostra
  todas as requsições feitas pelas filiais.
  */   
  inputAnotherTable(event, type) {
    if (type === 'new') {
      this.itemsSelectedFork.push({
        c1_filial : event.c1_filial,
        c1_num    : event.c1_num    ,
        c1_produto: event.c1_produto,
        c1_item   : event.c1_item   ,
        c1_descri : event.c1_descri ,
        b1_grupo  : event.b1_grupo  ,
        bm_desc   : event.bm_desc   , 
        c1_datprf : event.c1_datprf ,
        c1_um     : event.c1_um     ,
        c1_quant  : event.c1_quant  ,
        c1_obs    : event.c1_obs    , 
        c1_solicit: event.c1_solicit,
        c1_filent : event.c1_filent ,
        c1_cc     : event.c1_cc     ,
        c1_conta  : event.c1_conta  ,
        c1_itemcta: event.c1_itemcta,
        c1_clvl   : event.c1_clvl   ,
        c1_grade  : event.c1_grade  ,
        c1_itemgrd: event.c1_itemgrd,
        c1_segum  : event.c1_segum  ,
        c1_qtsegum: event.c1_qtsegum,
        c1_ident  : event.c1_ident  ,
        ac9_codent: event.ac9_codent,
        c1_quje   : event.c1_quje   ,
        c1_codorca: event.c1_codorca,
        c1_tipo   : event.c1_tipo   ,
        c1_fabrica: event.c1_fabrica,
        c1_lojfabr: event.c1_lojfabr,
        c1_nomapro: event.c1_nomapro,
        c1_accnum : event.c1_accnum ,
        c1_accitem: event.c1_accitem,
        c1_xtiposc: event.c1_xtiposc,
        remove    : event.remove    ,
        $selected : event.$selected
      });
      this.itemsSelectedFork = [...this.itemsSelectedFork];
    } else {
      const index = this.itemsSelectedFork.findIndex(el => el.c1_filial === event.c1_filial && el.c1_num === event.c1_num && 
                    el.c1_produto === event.c1_produto && el.c1_item === event.c1_item && el.c1_itemgrd === event.c1_itemgrd);
                    
      this.tableSelectedItems.removeItem(index);
      this.itemsSelectedFork = [...this.tableSelectedItems.items];
      this.handleUnselectItemCP(event);
    }
  }

  /***** adjustItemsTable 
  Percorre os dados do array do grid, quando o usuário clica no botão para selecionar todos, para tratamento e envio
  para a função que vai isnerir os itens na tabela centralizadora.
  */   
  adjustItemsTable(items, type) {
    let nSizeData = items.length;
    let nFor = 0;
    this.itemsSelectedFork = [];
    if (type === "new") {
      for(nFor = 0; nFor < nSizeData; nFor++) {
        this.inputAnotherTable(items[nFor], type);
      }
    } else {
      this.itemsSelectedFork = [];
    }
  }


  //Limpa o array de items selecionados do grid de opção selecionada (a direita)
  cleanTableData() {
    this.itemsSelectedFork = [];
  }


  /*Função que vai executar a migração e criação de novas SC, conforme remote, na função MAPComCent
  quotationsItems: items válidos para a cotação
  */
  centralizedchangeBranchItems(correctQuotationItems){
    this.loading = true;
    correctQuotationItems["selectedBranch"] = this.branchCode;
    this.purchaseRequestsService.centralizedRequestMigration(correctQuotationItems)
    .subscribe({
      next: (response) => {
        this.loading = false;
        this.temporaryStorageService.setValue('quotationItems', response);
        let quotationItems = response;
        if (response.items.length > 0 ) {
          this.router.navigate(
            ['/necessidade-de-compra'],
            { state: { quotationItems } }
          ); 
        }
      },
      error: (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })  
  }

  //Ações da página ativa. No caso, vamos incluir o botão para abrir a tela de Compra Centralizada. 
  public readonly actions: Array<PoPageAction> = [
    { label: this.literals?.str0245, url: '/necessidade-de-compra'}
  ];
  
}