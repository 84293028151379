<form [formGroup]="searchForm "class="responsive-form">

  <div class="po-row">
    <!-- Selecione o período -->
    <po-datepicker-range class="po-mr-1 search-date"
      name="datepickerRange"
      formControlName="date"
      [p-label]="literals?.str0011"
      (p-change)="changeInputDateFilter()">
    </po-datepicker-range>

    <div class="search-lookups">
      <!-- Busque por código de produto -->
      <pgc-lookup
        class="lookup-input"
        fieldToFilter="b1_cod"
        [label]="literals?.str0012"
        [columns]="productDescription"
        endpointURL="genericList"
        endpointURLParams="alias=SB1"
        [isFramework]="true"
        [modelChange]="modelLookupChange"
        [executeRequestByValue]="false"
        (searchedValue)="changeInputSearch($event)">
      </pgc-lookup>
      <!-- Busca avançada -->
      <div class="po-page-list-filter-search ng-star-inserted po-ml-1 po-mt-4">
        <span class="po-page-list-filter-search-link" (click)="pageSlide.open()">{{ literals.str0013 }}</span>
      </div>

    </div>

    <div *ngIf="showSwitchValidadeProductGroup"
    class="po-ml-3 po-mr-1 po-text-nowrap switch">
      <po-switch
        [p-label]="literals?.str0444"
        [p-label-on]="literals?.str0099"
        [p-label-off]="literals?.str0100"
        [(ngModel)]="validateDiffProductGroups"
        (p-change)="handleSwitchValidateProductGroup()"
        [ngModelOptions]="{standalone: true}">
      </po-switch>
    </div>

    <div *ngIf="showSwitchValidadeProductGroup">
      <span
        class="po-icon po-icon-exclamation exclamation"
        [p-tooltip]="literals?.str0445"
        p-tooltip-position="right-top">
      </span>
    </div>
  </div>

  <po-disclaimer-group
    [p-disclaimers]="disclaimers"
    (p-change)="changeFilters(disclaimers)"
    (p-remove)="removeDisclaimer($event)"
    (p-remove-all)="handleRemoveAllFilters()">
  </po-disclaimer-group>

</form>

<po-page-slide #pageSlide [p-title]="literals?.str0013">

  <form [formGroup]="advancedFilterForm">

    <div class="po-row">
      <div class="po-md-6">
        <pgc-lookup
          name="c1_num"
          [form]="advancedFilterForm"
          formField="c1_num"
          fieldToFilter="c1_num"
          [label]="literals?.str0015"
          [columns]="requestCodeColumns"
          [endpointURL]="purchaseRequestURL"
          [isFramework]="false"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_descri"
          [form]="advancedFilterForm"
          formField="c1_descri"
          fieldToFilter="b1_desc"
          [label]="literals?.str0018"
          [columns]="productDescription"
          endpointURL="genericList"
          endpointURLParams="alias=SB1"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="b1_grupo"
          [form]="advancedFilterForm"
          formField="b1_grupo"
          fieldToFilter="bm_grupo"
          [label]="literals?.str0439"
          [columns]="productGroupColumns"
          endpointURL="genericList"
          endpointURLParams="alias=SBM"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="bm_desc"
          [form]="advancedFilterForm"
          formField="bm_desc"
          fieldToFilter="bm_desc"
          [label]="literals?.str0440"
          [columns]="productGroupColumns"
          endpointURL="genericList"
          endpointURLParams="alias=SBM"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_solicit"
          [form]="advancedFilterForm"
          formField="c1_solicit"
          fieldToFilter="c1_solicit"
          [label]="literals?.str0024"
          [columns]="requesterColumns"
          [endpointURL]="purchaseRequestURL"
          [isFramework]="false"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_filial"
          [form]="advancedFilterForm"
          formField="c1_filial"
          fieldToFilter="Code"
          [label]="literals?.str0025"
          [columns]="branchColumns"
          endpointURL="environment/v1/branches"
          [endpointURLParams]="branchesURLParams"
          [isFrameworkBranches]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_filent"
          [form]="advancedFilterForm"
          formField="c1_filent"
          fieldToFilter="Code"
          [label]="literals?.str0026"
          [columns]="branchColumns"
          endpointURL="environment/v1/branches"
          [endpointURLParams]="branchesURLParams"
          [isFrameworkBranches]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_cc"
          [form]="advancedFilterForm"
          formField="c1_cc"
          fieldToFilter="ctt_custo"
          [label]="literals?.str0027"
          [columns]="coastCenterColumns"
          endpointURL="genericList"
          endpointURLParams="alias=CTT"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_conta"
          [form]="advancedFilterForm"
          formField="c1_conta"
          fieldToFilter="ct1_conta"
          [label]="literals?.str0028"
          [columns]="ledgerAccountColumns"
          endpointURL="genericList"
          endpointURLParams="alias=CT1"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_itemcta"
          [form]="advancedFilterForm"
          formField="c1_itemcta"
          fieldToFilter="ctd_item"
          [label]="literals?.str0029"
          [columns]="accountingItemColumns"
          endpointURL="genericList"
          endpointURLParams="alias=CTD"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

      <div class="po-md-6">
        <pgc-lookup
          name="c1_clvl"
          [form]="advancedFilterForm"
          formField="c1_clvl"
          fieldToFilter="cth_clvl"
          [label]="literals?.str0030"
          [columns]="valueClassColumns"
          endpointURL="genericList"
          endpointURLParams="alias=CTH"
          [isFramework]="true"
          [modelChange]="modelLookupChange"
          [cleanModel]="cleanLookupModel"
          [cleanField]="cleanLookupField"
          [executeRequestByValue]="false"
          >
        </pgc-lookup>
      </div>

    </div>

    <div class="po-row buttons">
      <po-button [p-label]="literals?.str0042" (p-click)="applyFilter(true)"> </po-button>
      <po-button [p-label]="literals?.str0043" p-kind="primary" (p-click)="applyFilter(false)">
      </po-button>
    </div>

  </form>

</po-page-slide>
