/* src/app/features/purchase-requests/requested-products/requested-products.component.css */
.disclaimer-bottom {
  margin: -16px 0 0 16px;
}
.text-area {
  margin-top: -40px !important;
}
.exclamantion {
  margin-top: 6px;
}
/*# sourceMappingURL=requested-products.component.css.map */
