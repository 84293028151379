<po-tabs>

  <!-- Em aberto -->
  <po-tab p-active="true" [p-label]="literals?.str0008" (p-click)="changeStatus(0)">

    <div *ngIf="isLoading">
      <po-loading-overlay
        p-screen-lock="true"
      ></po-loading-overlay>
    </div>

    <tgc-search class="po-m-1"
      (inputDateFilter)="handleChangeInputDateFilter($event)"
      (inputSearchFilter)="handleInputSearchFilter($event)"
      (inputAdvancedFilter)="handleAdvancedFilterForm($event)"
      (changeInputAdvancedFilter)="handleChangeInputAdvancedFilter($event)"
      (changeSwitchValidateProductGroup)="handleSwitchValidateProductGroup($event)"
      (removeAllFilters)="handleRemoveAllFilters()"
      [showSwitchValidadeProductGroup]="true"
      [tabNumber]="0"
      [currentTab]="requestStatus"
      urlParam="open">
    </tgc-search>

    <div *ngIf="openItems?.length">
      <p class="po-font-text-small">
        {{ literals?.str0109 + ' ' + openItems?.length + ' ' + literals?.str0110 + ' ' + openTotal + ' ' + literals?.str0059 }}
      </p>
    </div>

    <po-table 
      #table
      #table1
      p-height="450"
      p-hide-batch-actions="true"
      p-hide-table-search="true"
      p-infinite-scroll="true"
      p-infinite-scroll-distance="80"
      p-sort="true"
      p-hide-text-overflow="true"
      [p-hide-columns-manager]="false"
      [p-columns]="columns"
      [p-items]="openItems"
      [p-striped]="true"
      [p-loading]="loading"
      [p-selectable]="true"
      p-spacing="small"
      [p-show-more-disabled]="!hasMoreOpenItems"
      (p-selected)="handleSelectItem($event)"
      (p-unselected)="handleUnselectItem($event)"
      (p-all-selected)="handleSelectAllItems($event)"
      (p-all-unselected)="handleUnselectAllItems()"
      (p-show-more)="getMoreOpenItems()"
      (p-change-visible-columns)="handleColumnChange(table1)"
      (p-restore-column-manager)="onRestoreColumns()">
    </po-table>

    <div class="wrapper" *ngIf="openItems?.length > 0 || partiallyAttendedItems?.length > 0" >

      <!-- Carrinho -->
      <po-tag *ngIf="quotationItems?.length > 0" class="cart" p-color="color-08" p-icon="po-icon po-icon-cart"
        p-value="{{ this.quotationItems?.length === 1 ? this.literals?.str0087 : this.literals?.str0088 }} {{ this.quotationItems?.length }}"
        (click)="handleOpenModalItemsSelected()">
      </po-tag>

      <!-- Botão Iniciar cotação -->
      <po-button class="btn-quote" p-kind="primary" [p-label]="literals?.str0032" [p-loading]="isLoading"
        [p-disabled]="quotationItems.length === 0 || isLoading" (p-click)="startQuote()">
      </po-button>
    </div>

  </po-tab>

  <!-- Parciamente Atendidas -->
  <po-tab [p-label]="literals?.str0009" (p-click)="changeStatus(1)">

    <tgc-search class="po-m-5"
      (inputDateFilter)="handleChangeInputDateFilter($event)"
      (inputSearchFilter)="handleInputSearchFilter($event)"
      (inputAdvancedFilter)="handleAdvancedFilterForm($event)"
      (changeInputAdvancedFilter)="handleChangeInputAdvancedFilter($event)"
      (removeAllFilters)="handleRemoveAllFilters()"
      [showSwitchValidadeProductGroup]="false"
      urlParam="partial"
      [tabNumber]="1"
      [currentTab]="requestStatus">
    </tgc-search>

    <div *ngIf="partiallyAttendedItems?.length">
      <p class="po-font-text-small">
        {{ literals?.str0109 + ' ' + partiallyAttendedItems?.length + ' ' + literals?.str0110 + ' ' + partiallyTotal + ' ' + literals?.str0059 }}
      </p>
    </div>

    <po-table 
      #table
      #table2
      p-spacing="small"
      p-height= "500"
      p-hide-table-search="true"
      p-infinite-scroll="true"
      p-infinite-scroll-distance="80"
      p-sort="true"
      p-hide-text-overflow="true"
      [p-hide-columns-manager]="false"
      [p-columns]="columns"
      [p-items]="partiallyAttendedItems"
      [p-striped]="true"
      [p-loading]="loading"
      [p-selectable]="false"
      [p-show-more-disabled]="!hasMorePartiallyAttendedItems"
      (p-show-more)="getMorePartiallyAttendedItems()"
      (p-change-visible-columns)="handleColumnChange(table2)"
      (p-restore-column-manager)="onRestoreColumns()"> 
    </po-table>

  </po-tab>

  <!-- Atendidas -->
  <po-tab [p-label]="this.literals.str0010" (p-click)="changeStatus(2)">

    <tgc-search class="po-m-5" (inputDateFilter)="handleChangeInputDateFilter($event)"
      (inputSearchFilter)="handleInputSearchFilter($event)" 
      (inputAdvancedFilter)="handleAdvancedFilterForm($event)"
      (changeInputAdvancedFilter)="handleChangeInputAdvancedFilter($event)"
      (removeAllFilters)="handleRemoveAllFilters()"
      [showSwitchValidadeProductGroup]="false"
      urlParam="attended"
      [tabNumber]="2"
      [currentTab]="requestStatus">
    </tgc-search>

    <div *ngIf="attendedItems?.length">
      <p class="po-font-text-small">
        {{ literals?.str0109 + ' ' + attendedItems?.length + ' ' + literals?.str0110 + ' ' + attendedTotal + ' ' + literals?.str0059 }}
      </p>
    </div>

    <po-table 
      #table
      #table3
      p-spacing="small"
      p-hide-table-search="true"
      p-height= "500"
      p-infinite-scroll="true"
      p-infinite-scroll-distance="80"
      p-sort="true"
      p-hide-text-overflow="true"
      [p-hide-columns-manager]="false"
      [p-columns]="columns"
      [p-items]="attendedItems"
      [p-striped]="true"
      [p-loading]="loading"
      [p-selectable]="false"
      [p-show-more-disabled]="!hasMoreAttendedItems"
      (p-show-more)="getMoreAttendedItems()"
      (p-change-visible-columns)="handleColumnChange(table3)"
      (p-restore-column-manager)="onRestoreColumns()">
    </po-table>
  </po-tab>

</po-tabs>

<!-- Modal Itesn selecionados/Carrinho de compras -->
<po-modal #itemsSelectedModal
  p-click-out="false"
  p-size="xl"
  [p-title]="literals.str0151"
  [p-primary-action]="primaryActionItemsSelectedModal">

  <po-table
    [p-loading]="loadingCart" 
    p-height="500"
    p-hide-text-overflow="true"
    [p-columns]="itemsSelectedColumns"
    [p-items]="itemsSelected"
    [p-striped]="true">
  </po-table>

</po-modal>

<!--Modal listagem de anexos-->
<po-modal #appendModal
  p-click-out="true"
  p-size="lg"
  [p-title]="literals?.str0046"
  [p-primary-action]="primaryActionAppendModal">
  <div *ngIf="downloadLoading">
    <po-loading-overlay
      p-screen-lock="true"
    ></po-loading-overlay>
  </div>
  <po-table
    p-height= "300"
    p-infinite-scroll="true"
    p-infinite-scroll-distance="80"
    p-sort="true"
    p-hide-text-overflow="true"
    [p-columns]="appendColumns"
    [p-items]="appendItems?.items"
    [p-striped]="true"
    [p-selectable]="false"
    [p-loading]="isLoadingAppendTable"
    [p-show-more-disabled]="!hasMoreAppends"
    (p-show-more)="getMoreAppends()">
  </po-table>

</po-modal>

<po-modal #invalidItemsModal
  p-click-out="false"
  p-size="auto"
  [p-title]="literals?.str0222"
  [p-primary-action]="primaryActionInvalidItemsModal"
  [p-secondary-action]="SecundaryActionInvalidItemsModal"
  >

  <po-table
    [p-hide-text-overflow]="true"
    p-hide-table-search="true"
    [p-auto-collapse]="true"
    [p-columns]="columnsInvalidItemsGrid"
    [p-items]="invalidItems"
    [p-sort]="true"
    [p-striped]="true">
  </po-table>

  <tgc-form-message [text]="literals.str0225"></tgc-form-message>
  <font class="po-font-text-large-bold po-mt-2">{{ literals.str0226 }}</font>

</po-modal>

<po-modal #OnlyInvalidItemsModal
  p-click-out="false"
  p-size="auto"
  [p-title]="literals?.str0222"
  [p-primary-action]="primaryOnlyInvalidItemsModal"
  >

  <po-table
    [p-hide-text-overflow]="true"
    [p-auto-collapse]="true"
    p-hide-table-search="true"
    [p-columns]="columnsInvalidItemsGrid"
    [p-items]="invalidItems"
    [p-sort]="true"
    [p-striped]="true">
  </po-table>

  <tgc-form-message [text]="literals?.str0224"></tgc-form-message>

</po-modal>

<po-modal #diffProductGroupsModal
  [p-title]="literals?.str0443"
  p-size="lg"
  [p-primary-action]="primaryDiffProductGroups"
  >
  <po-info
  [p-value]="messageDiffProductGroups"></po-info>
</po-modal>

