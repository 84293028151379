/* src/app/features/purchase-requests/purchase-requests-list/centralized-purchase/centralized-purchase.component.css */
.head {
  display: flex;
  min-height: 173px;
  flex-direction: column;
  justify-content: space-between;
}
.records {
  margin-bottom: 2px;
}
.buttons {
  display: flex;
  margin-top: 50px;
}
.buttons > po-button {
  margin-right: 8px;
}
.wrapper {
  height: 40px;
  margin-top: 4x;
  padding: 4px;
  margin-bottom: 1rem;
}
.cart {
  cursor: pointer;
}
.btn-quote {
  float: right;
  margin: 5px 5px 0 0;
}
.warning {
  color: #be3e37;
}
.alertForm {
  margin-bottom: 20px;
}
/*# sourceMappingURL=centralized-purchase.component.css.map */
