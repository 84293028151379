<po-modal #emailModal
  p-size="xl"
  p-click-out="false"
  [p-hide-close]="true"
  [p-title]="modalTitle">

  <div class="po-row">
    <po-info [p-label]="literals?.str0162" class="po-md-6"></po-info>
  </div>
  <po-grid
    [p-columns]="columnsProvidersGrid"
    [p-data]="supplierProposal">
  </po-grid>

  <div class="po-row po-mt-2">
    <po-switch
      name="sendEmail"
      [(ngModel)]="sendEmail"
      [p-label]="literals?.str0165"
      [p-label-off]="literals?.str0100"
      [p-label-on]="literals?.str0099"
      (ngModelChange)="onSendConditionsChange()"
      >
    </po-switch>
  </div>

  <div *ngIf="sendEmail && hasSendPurchaserWf" class="po-row po-mt-2">
    <po-switch
      name="sendEmailPurchaser"
      [(ngModel)]="sendpurchaserWfModel"
      [p-label]="literals?.str0800"
      [p-label-off]="literals?.str0100"
      [p-label-on]="literals?.str0099">
    </po-switch>
  </div>

  <!-- switch selecionar condições de pagamento-->
  <div *ngIf="sendEmail" class="po-row po-mt-2">
    <po-switch
      [p-tooltip]=literals.str0636
      [(ngModel)]="sendConditions"
      p-label={{literals?.str0635}}
      [p-label-off]="literals?.str0100"
      [p-label-on]="literals?.str0099"
      (ngModelChange)="onSendConditionsChange()"
      >
    </po-switch>
  </div>

    <po-multiselect *ngIf="sendConditions && sendEmail"
      class="po-lg-12 po-mt-2"
      [(ngModel)]="selectedConditions" 
      name="paymentConditions"
      p-label={{literals?.str0250}} 
      [p-options]="optionsConditions">
    </po-multiselect>

    <po-disclaimer-group [p-disclaimers]="filters" (p-change)="changeFilters(filters)" *ngIf="filters.length > 0"></po-disclaimer-group>
    
    <po-textarea
        name="obsEmail"
        [p-label]="literals.str0253"
        [(ngModel)]="obsEmail"
        [p-help]="literals.str0312"
    ></po-textarea>
    <attach-documents></attach-documents>
    <po-modal-footer>
      <po-button [p-label]="literals?.str0092" (p-click)="closeConfirmDialog()"> </po-button>
      <po-button p-kind="primary" [p-label]="literals?.str0293" (p-click)="confirmFinalyzeProcess()" [p-disabled]="!sendEmail"> </po-button>
    </po-modal-footer>
</po-modal>