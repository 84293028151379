import { PoBreadcrumb, PoDialogService, PoNotificationService } from '@po-ui/ng-components';
import { DateHandler } from 'src/app/shared/helper/date-handler';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PurchaseRequestsService } from './../shared/services/purchase-requests.service';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { PurchaseRequest } from './../shared/models/purchase-requests.model';
import { CompanyInfoService } from 'src/app/shared/services/company-info.service';
import { MenuService } from '../shared/services/menu.service';
import { Utils } from 'src/app/shared/helper/utils';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
    selector: 'tgc-quote-request',
    templateUrl: './quote-request.component.html',
    styleUrls: ['./quote-request.component.css'],
    standalone: false
})
export class QuoteRequestComponent implements OnInit {

  literals: LiteralsWords = this.literalsService.getLiterals();
  formQuoteData: FormGroup;
  formRequestedProducts: Array<PurchaseRequest> = [];
  quotationItems: any;
  isLoading: boolean = false;
  isQuoteFormDataValid: boolean = false;
  
  public readonly breadcrumb: PoBreadcrumb = {
    items: [{ label: this.literals.str0002, action: this.navigateBack.bind(this)}, { label: this.literals.str0065 }]
  };

  constructor(
    private literalsService: LiteralsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private purchaseRequestsService: PurchaseRequestsService,
    private dateHandler: DateHandler,
    public poDialog: PoDialogService,
    private poNotification: PoNotificationService,
    private companyInfoService : CompanyInfoService,
    private menuService: MenuService,
    private utils: Utils,
    private temporaryStorageService: TemporaryStorageService) { }

  ngOnInit(): void {
    this.buildForm();
    this.getCompany();
    this.getSelectedItemsFromLocalStorage();
  }

  //-- Cria formulário
  buildForm(): void {
    this.formQuoteData = this.formBuilder.group({
      quoteGroupNickname: [''],
      documentType: [''],
      evaluatesUnsuccessfulProposal: [''],
      status: [''],
      emissionDate: [''],
      receiptQuote: [''],
      groupQuotes: [''],
      necessityDate: [''],
      numberProducts: [''],
      cnpj: '',
      socialReason: '',
      deliveryAddress: '',
      zipCode: '',
      purchaser : '',
      purchaserCode : '',
      fisicalplacedelivery: '',

    });
  }

  getCompany(){
    this.menuService.loading(true);

    this.companyInfoService.getCompanyInfo().subscribe({
      next: (element) => {
        const value = {
          'items':[
            this.utils.removeInvalidCharactersFromObject(element.items[0])
          ]
        };

        const cnpj: string = value.items[0].m0_cgc ? value.items[0].m0_cgc : this.literals.str0122; //-- Sem informação
        const socialReason: string = value.items[0].m0_nomecom ? value.items[0].m0_nomecom : this.literals.str0122;
        const deliveryAddress: string = `${value.items[0].m0_endent} - ${value.items[0].m0_bairent} - ${value.items[0].m0_cident}/${value.items[0].m0_estent}`;
        const zipCode: string = value.items[0].m0_cepent;

        this.formQuoteData.get('cnpj').setValue(cnpj);
        this.formQuoteData.get('socialReason').setValue(socialReason);
        this.formQuoteData.get('deliveryAddress').setValue(deliveryAddress);
        this.formQuoteData.get('zipCode').setValue(zipCode);

        this.menuService.loading(false);
      }, error: (error) => {
        this.formQuoteData.get('cnpj').setValue(this.literals.str0122); //-- Sem informação
        this.formQuoteData.get('socialReason').setValue(this.literals.str0122);
        this.formQuoteData.get('deliveryAddress').setValue(this.literals.str0122);
        this.formQuoteData.get('zipCode').setValue('');

        this.menuService.loading(false);
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    });
  }

  getSelectedItemsFromLocalStorage(): void {
    const formQuoteData = this.temporaryStorageService.getValue('formQuoteData');
    const quotationItems = this.temporaryStorageService.getValue('quotationItems');

    if (quotationItems) {
      this.quotationItems = quotationItems;
    }
    
    if (formQuoteData) {
      this.formQuoteData.patchValue(formQuoteData);
    }
  }

  handleQuoteDataForm(form: FormGroup): void {
    if (this.formQuoteData) {
      this.formQuoteData.patchValue(form.value);
      this.isQuoteFormDataValid = form.valid;
    }
  }

  handleRequestedProductsForm(form: FormGroup): void {
    this.formRequestedProducts = form.value.notesupplier;
  }

  cancel(): void {
    this.poDialog.confirm({
      title: this.literals.str0228,
      message: this.literals.str0127,
      literals: { cancel: this.literals?.str0100, confirm: this.literals?.str0099 },
      cancel: () => {  },
      confirm: () => this.cancelQuotation()
    });
  }

  cancelQuotation(): void {
    this.purchaseRequestsService.clearQuotationFromLocalStorage();
    this.router.navigate(['/necessidade-de-compra']);
    this.poNotification.warning(this.literals?.str0227);
  }

  // Botão proximo passo envia dados da cotação para sessionStorage
  nextStep(): void {
    let formQuoteData = Object.assign(this.formQuoteData ? this.formQuoteData.value : {});
    let quotationItems = Object.assign(this.quotationItems ? this.quotationItems : {});

    formQuoteData.emissionDate = this.dateHandler.getCurrentDate();
    formQuoteData.numberProducts = this.quotationItems?.items?.length;
    formQuoteData.receiptQuote = this.dateHandler.convertStringToProtheusDate(formQuoteData.receiptQuote);
    formQuoteData.cnpj = formQuoteData.cnpj;
    formQuoteData.quoteGroupNickname = formQuoteData.quoteGroupNickname.toUpperCase();
    formQuoteData.purchaser = sessionStorage.getItem('user');
    formQuoteData.purchaserCode = sessionStorage.getItem('userId').trim() ? sessionStorage.getItem('userId') : '';
    formQuoteData.fisicalplacedelivery = formQuoteData.fisicalplacedelivery.toUpperCase();

    this.temporaryStorageService.setValue('quotationItems', quotationItems);
    this.temporaryStorageService.setValue('formQuoteData', formQuoteData);

    this.router.navigate(['/necessidade-de-compra/selecao-de-fornecedores']);
  }

  navigateBack(): void {
    this.purchaseRequestsService.clearQuotationFromLocalStorage();
    this.router.navigate(['/necessidade-de-compra']);
  }
}
