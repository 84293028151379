<po-accordion class="accordion po-mt-5">
  <po-accordion-item [p-label]="literals?.str0655">
    <po-widget>
      
      <h2>{{ literals?.str0078 }}</h2>
      <form [formGroup]="form">
        <div class="po-row po-mt-2">
          <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
            <po-select name="order"
              [p-label]="literals?.str0079"
              [p-options]="order"
              [p-disabled]="!quotationItems?.items || quotationItems?.items?.length === 0"
              (p-change)="orderItems($event)">
            </po-select>
          </div>

          

          <div class="po-xl-3 po-lg-3 po-md-3 po-sm-3">
            <po-info
              [p-label]="literals?.str0078"
              [p-value]="quotationItems?.items?.length > 0 ? quotationItems?.items?.length : literals?.str0091">
            </po-info>
          </div>

        </div>    
          
        <po-widget *ngFor="let item of quotationItems?.items, let i = index">

          <po-disclaimer-group *ngIf="item?.disclaimers?.length > 1"
            [p-title]="literals?.str0080"
            [p-disclaimers]="item?.disclaimers">
          </po-disclaimer-group>

          <p class="po-font-text-small disclaimer-bottom" *ngIf="item.purchaserequests.length > 1">{{ literals?.str0081 }}</p>

          <div class="po-row po-mt-2">
            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
              <po-info [p-label]="literals?.str0082" [p-value]="item?.c1_produto"></po-info>
            </div>

            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
              <po-info [p-label]="literals?.str0083" [p-value]="item?.c1_descri"></po-info>
            </div>

            <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
              <po-info [p-label]="literals?.str0084" [p-value]="item?.c1_um"></po-info>
            </div>

            <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
              <po-info [p-label]="literals?.str0085" [p-value]="item?.c1_quant | number : toRound()"></po-info>
            </div>
          </div>

          <div class="po-row po-mt-2">
            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
              <po-info [p-label]="literals?.str0019" [p-value]="formatDate(item?.c1_datprf)"></po-info>
            </div>

            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
              <po-info [p-label]="literals?.str0439" [p-value]="item?.b1_grupo"></po-info>
            </div>

            <div class="po-xl-4 po-lg-4 po-md-4 po-sm-4">
              <po-info [p-label]="literals?.str0440" [p-value]="item?.bm_desc"></po-info>
            </div>

          </div>
          
          <div class="po-row po-mt-3">
            <po-switch 
              [p-label]="literals.str0602"
              p-label-off=" "
              p-label-on=" "
              [(ngModel)]="item.shownoterequest"
              (p-change)="changeShowNoteRequest($event, i)"
              [ngModelOptions]="{standalone: true}"> 
            </po-switch>

            <span 
              class="po-icon po-icon po-icon-exclamation po-ml-1 exclamantion"
              p-tooltip="{{literals.str0615}}"
              p-tooltip-position="right-top"
              >
            </span>
            
            <div *ngIf="item.shownoterequest" class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
              <po-textarea
                class="text-area"
                name="noterequest"
                p-readonly="true"
                [(ngModel)]="item.c1_obs"
                [ngModelOptions]="{standalone: true}">
              </po-textarea>
            </div>
          </div>

          <div class="po-row po-mt-2">
            <po-switch [p-label]="literals?.str0086" p-label-off=" " p-label-on=" " (p-change)="changeShowNoteSupplier($event, i)"> </po-switch>
            
            <span 
              class="po-icon po-icon po-icon-exclamation po-ml-1 exclamantion"
              p-tooltip="{{literals.str0616}}"
              p-tooltip-position="right-top"
              >
            </span>

            <div *ngIf="item.shownotesupplier" class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
              <po-textarea
                class="text-area"
                name="notesupplier"
                [(ngModel)]="item.notesupplier"
                [ngModelOptions]="{standalone: true}"
                p-minlength="0"
                p-maxlength="600"
                (p-change)="changeNoteEvent(item, $event)">
              </po-textarea>
            </div>
          </div>

        </po-widget>

      </form>
    </po-widget>
  </po-accordion-item>
</po-accordion>
