/* src/app/features/purchase-requests/quote-request/quote-data/quote-data.component.css */
.d-block {
  display: block;
}
.text {
  display: block;
}
.po-icon {
  display: block;
  align-items: center;
  margin-top: 46px;
}
.warning {
  color: #be3e37;
}
/*# sourceMappingURL=quote-data.component.css.map */
