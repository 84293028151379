import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PoDialogService, PoDisclaimer, PoModalComponent, PoMultiselectOption, PoNotification, PoNotificationService } from '@po-ui/ng-components';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { SupplierModel } from '../../shared/models/supplier.model';
import { PaymentConditionService } from 'src/app/features/quotes/shared/services/payment-condition.service';
import { EMPTY, catchError, switchMap } from 'rxjs';
import { PreferencesProfileService } from 'src/app/shared/services/preferences-profile.service';
import { Utils } from 'src/app/shared/helper/utils';
import { PurchaseRequestsService } from '../../shared/services/purchase-requests.service';
import { Router } from '@angular/router';
import { MingleService } from '@totvs/mingle';
import { MenuService } from '../../shared/services/menu.service';
import { PgcProfileItem } from 'src/app/shared/models/pgc-profile.model';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

declare var totvstec: any; //-- Variável disponível apenas quando a aplicação é executada dentro do Protheus

@Component({
    selector: 'pgc-supplier-email',
    templateUrl: './supplier-email.component.html',
    styleUrl: './supplier-email.component.css',
    standalone: false
})
export class SupplierEmailComponent implements OnInit {
  sendConditions: boolean = false;
  sendEmail: boolean = false;
  columnsProvidersGrid: Array<any>;
  selectedConditions: Array<any> = [];
  filteredItems: Array<any> = [];
  items: Array<any> = [];
  filters: Array<PoDisclaimer> = [];
  optionsConditions: Array<PoMultiselectOption> = [];
  literals: LiteralsWords = this.literalsService.getLiterals();
  user: string = '';
  tdnLink: string = 'https://tdn.totvs.com/pages/releaseview.action?pageId=680260500';
  obsEmail: string = '';
  profile: PgcProfileItem;
  quotationWorkflow: any;
  structSC8: any;
  hasSendPurchaserWf: boolean = false;
  sendpurchaserWfModel: boolean = false;
  
  @Input() modalTitle: string = '';
  @Input() quotationNumber: string = '';
  @Input() supplierProposal: SupplierModel[];
  @Input() isSupplierSelection: boolean = false;
  @Input() sendPurchaserWf: boolean = false;
  @ViewChild('emailModal', { static: false }) emailModal: PoModalComponent;

  constructor(
    private literalsService: LiteralsService,
    private paymentConditionService: PaymentConditionService,
    private preferencesProfileService: PreferencesProfileService,
    private utils: Utils,
    private poNotification: PoNotificationService,
    private purchaseRequestsService: PurchaseRequestsService,
    private router: Router,
    private poDialog: PoDialogService,
    private mingleService: MingleService,
    private menuService: MenuService,
    private temporaryStorageService: TemporaryStorageService
  ) {
    this.columnsProvidersGrid = [
      {
        property: 'suppliercode',
        label: this.literals?.str0113,
        readonly: true,
        width: '160px',
      },
      {
        property: 'store',
        label: this.literals?.str0114,
        readonly: true,
        width: '150px'
      },
      {
        property: 'companyname',
        label: this.literals?.str0073,
        readonly: true,
        width: '250px'
      },
      {
        property: 'emaillist',
        label: this.literals?.str0115,
        width: '250px'
      },
      {
        property: 'areacode',
        label: this.literals?.str0175,
        readonly: true,
        width: '150px'
      },
      {
        property: 'telephone',
        label: this.literals?.str0116,
        readonly: true,
        width: '150px'
      },
    ];
  }

  ngOnInit(): void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);
    this.profile = this.utils.getProfile();
    this.sendEmail = this.profile.sendEmail;
    this.sendConditions = this.profile.sendConditions;
    this.sendpurchaserWfModel = this.isSupplierSelection ? this.profile.sendPurchaserWf : this.sendPurchaserWf;
    this.user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '{}';
    this.structSC8 = this.temporaryStorageService.getValue('structSC8')?.SC8;
    this.hasSendPurchaserWf = !!this.utils.getFieldName('structDHU', 'DHU', 'DHU_COMPWF');
    this.getPaymentCondition();
  }

  // Limpar as informações quando sendConditions ou sendEmail for definido como false
  onSendConditionsChange() {
    if (!this.sendConditions || !this.sendEmail) {
      this.selectedConditions = [];
    }
  }

  changeFilters(filters: Array<PoDisclaimer>) {
    filters.length > 0 ? this.filter(filters) : this.resetFilters();
    this.clearFieldsIfNoFilter('name', 'terrain', 'climate');
  }

  filter(filters: Array<PoDisclaimer>) {
    const filterCondition = (filter, item) =>
      item[filter.property].toLocaleLowerCase().includes(filter.value.toLocaleLowerCase());
    const filterItems = item => filters.every(filter => filterCondition(filter, item));

    this.items ? this.filteredItems = this.items?.filter(filterItems) : null;
  }

  clearFieldsIfNoFilter(...fields: Array<string>) {
    const fieldHaveNoFilter = field => !this.filters.some(filter => filter.property === field);

    const fieldsWithoutFilter = fields.filter(field => this[field] && fieldHaveNoFilter(field));

    fieldsWithoutFilter.forEach(field => (this[field] = undefined));
  }

  resetFilters() {
    this.filteredItems = [...(this.items || [])];
  }

  //Método para obter condições de pagamento para envio do WF
  getPaymentCondition() {
    this.paymentConditionService.getPaymentCondition(1, 10).pipe(
      switchMap((firstResponse) => {
        const allConditions = firstResponse.remainingRecords + 10;
        return this.paymentConditionService.getPaymentCondition(1, allConditions); // Retorna todas as condições de pagamento
      }),
      catchError((error) => {
        console.error( this.literals?.str0634 , error);
        return EMPTY; // Retorna um Observable vazio para que a cadeia de observáveis não quebre
      })
    ).subscribe(
      (secondResponse) => {
        const conditionsList = secondResponse.items;
        this.optionsConditions = conditionsList.map((e) => ({
          value: e.e4_codigo,
          label: e.e4_descri,
        }));
      }
    );
  }

  // Abre o modal
  open():void {
    this.emailModal.open();
  }

  // Fecha o modal
  close():void {
    this.emailModal.close();
  }

  // Monta body para envio do workflow
  getSuppliersWorkflow(): Object {
    let suppliersWF = Array(this.supplierProposal.length);
    let quotationWF;

    for (let i = 0; i < this.supplierProposal.length; i++){
      suppliersWF[i] = {
        "supplier": this.supplierProposal[i].suppliercode,
        "store": this.supplierProposal[i].store,
        "email": this.supplierProposal[i].emaillist?.trim() ? this.supplierProposal[i].emaillist.trim() : this.supplierProposal[i].email.trim(),
        "corporatename" : this.supplierProposal[i].companyname,
      };
    }

    quotationWF = {
      "quotation": this.quotationNumber,
      "message": this.obsEmail,
      "sendAttachment": this.temporaryStorageService.getValue('hasAttachment'),
      "suppliers": suppliersWF,
      "selectedConditions": this.selectedConditions,
      "sendPurchaserWf": this.sendpurchaserWfModel
    };
    return quotationWF;
  }

  //-- Função que atualiza o profile
  needUpdateProfile(): void {
    this.profile = this.utils.getProfile();
    this.profile.sendEmail = this.sendEmail;
    this.profile.sendConditions = this.sendConditions;
    this.profile.sendPurchaserWf = this.sendpurchaserWfModel;
    this.utils.updateProfile(this.profile);
  }

  //-- Valida tamanho da string de email do campo C8_EMAILWF
  validateWFEmailSize(): boolean {
    let isValid = true;
    const fieldEmailWF = this.structSC8?.fields.find((element) => element?.field === 'C8_EMAILWF'); //-- Busca o campo C8_EMAILWF
    const items = this.quotationWorkflow?.suppliers;

    if (items && fieldEmailWF) {
      for(let i = 0; i < items.length; i++){
        if (items[i]?.email.length > fieldEmailWF?.size) {
          this.poNotification.warning(`${this.literals.str0436} ${items[i]?.supplier}-${items[i]?.store}. ${this.literals.str0327} C8_EMAILWF ${this.literals.str0328}`);
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  // Enviar email de solicitação 
  confirmFinalyzeProcess(): void {
    this.needUpdateProfile();
    this.menuService.loading(true);

    if (this.sendEmail) {
      this.quotationWorkflow = this.getSuppliersWorkflow();

      if (this.validateWFEmailSize()) {
        this.purchaseRequestsService.sendEmail(this.quotationWorkflow)
        .subscribe((response) => {
          this.menuService.loading(false);
          this.emailModal?.close();
          if (this.isSupplierSelection) {
            this.router.navigate(['/necessidade-de-compra']);
          }
          this.temporaryStorageService.clearValue('quotationCode');
          this.poNotification.success(response.message);
        }, error => {
          if(error.status == 500){
            this.menuService.loading(false);
            const poNotification: PoNotification = {
              message: this.literals.str0413,
              actionLabel: this.literals.str0167,
              action: () => window.open(this.tdnLink),
            };
            this.poNotification.warning(poNotification);
          }
          else{
            this.menuService.loading(false);
            this.poNotification.error(this.utils.getErrorMessage(error));
          }
        });
      } else {
        this.menuService.loading(false);
      }
    } else {
      this.menuService.loading(false);
    }
  }

  // Dialog de confirmação
  closeConfirmDialog() {
    this.poDialog.confirm({
      title: this.literals.str0183,
      message: this.literals.str0184,
      literals: { cancel: this.literals?.str0100, confirm: this.literals?.str0099 },
      cancel: () => {  },
      confirm: () => this.closeModal()
    });
  }

  // Fecha modal
  closeModal(): void {
    this.needUpdateProfile();

    if (this.isSupplierSelection) {
      this.router.navigate(['/necessidade-de-compra']);
    }
    this.poNotification.success(this.literals?.str0185);
    this.emailModal?.close();
  }
}
