
  <po-widget
    class="padding-widget"
    p-no-shadow = "true">
    <div class="po-font-subtitle po-pb-lg-5 po-pb-md-5">{{literals?.str0075}}</div>

    <div class="upload-box" [class.upload-hover]="isHovering"
        (drop)="drop($event)" (dragover)="allowDrop($event)" (dragleave)="dragLeave($event)" (dragenter)="dragEnter($event)" (click)="openFileDialog()">
      <div
        class="po-row po-pt-xl-5 po-pb-xl-5 po-pt-lg-5 po-pb-lg-5">
        <div class="po-xl-12 container-attach" [class.upload-opacity]="isHovering">
          <div class="align-center">
            <span class="po-row po-icon po-icon-upload big-upload-icon po-text-center"></span>
            <span class="po-row po-font-text-bold align-center">
              {{literals?.str0187}}
            </span>
            <span class="po-row po-font-text align-center information-font">
              {{literals?.str0188}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <input
      id="fileButton"
      [hidden]="true"
      type="file"
      [multiple]="true"
      (change)="onFileChange($event)">
    <br>

    <div *ngFor="let file of labelsToUpload; let i = index">
      <div class="po-row">
        <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6" [class.error-upload]="file.code >= 400" [class.success-upload]="file.code === 200" >
          <div class="align-left"  >
            <span  class="po-icon icon-file-format" [ngClass]="getIconFromStatus(file.type)"></span>
            <span class="po-font-text">{{file.name}}</span>
          </div>
        </div>
        <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
          <!-- ARRUMAR ESSE ALIGN-RIGHT -->
          <div class="align-right" style="vertical-align: middle;">
            <span class="po-icon icon-file-status po-mr-1" style="vertical-align: middle !important;" [ngClass]="getIconStatus(file.status)"></span>
            <span class="po-font-text po-mr-1" style="vertical-align: middle !important;">{{file.labelStatus}}</span>
            <po-button
              p-tooltip="{{literals?.str0149}}"
              class="btn-only-icon po-mr-1"
              p-icon="po-icon-download"
              p-type="default"
              (p-click)="downloadDocument(file)">
            </po-button>
            <po-button
              p-tooltip="{{literals?.str0144}}"
              class="btn-only-icon"
              p-icon="po-icon-close"
              p-danger="true"
              [p-disabled]="file.status === 'loading' || disableDelete"
              (p-click)="cancelUpload(i)">
            </po-button>
          </div>
        </div>
      </div>
      <po-divider></po-divider>
    </div>

  </po-widget>
