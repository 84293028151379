/* src/app/features/purchase-requests/supplier-selection/supplier-selection.component.css */
* {
  color: #4a5c60;
}
.container {
  width: 100% !important;
}
po-table {
  width: 100%;
}
::ng-deep .po-checkbox {
  font-weight: bold;
}
.button-load {
  margin: 0 auto;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 40%;
}
.button {
  margin-left: 10px;
}
.buttonsSubmit {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
}
/*# sourceMappingURL=supplier-selection.component.css.map */
