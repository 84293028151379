<po-widget>
  <form [formGroup]="form">
    <h2>{{ literals?.str0066 }}</h2>
    <div class="po-row po-mt-2">
      <div class="po-xl-7 po-lg-7 po-md-5 po-sm-5">
        <po-input name="input" p-no-autocomplete="true" p-auto-focus="true" formControlName="quoteGroupNickname" [p-label]="literals?.str0068 + '*'"
          (p-change-model)="sendStatusForm()">
        </po-input>
        <tgc-form-message *ngIf="form.get('quoteGroupNickname').errors?.maxlength" [text]="literals.str0143"></tgc-form-message>
      </div>

      <span class="po-icon po-icon-exclamation" [p-tooltip]="literals.str0145 + ' ' +  literals.str0142"></span>

    </div>

    <div class="po-row po-mt-2">
      <div class="po-xl-3 po-lg-3 po-md-4 po-sm-4">
        <po-datepicker
          p-no-autocomplete="true"
          name="receiptQuote"
          formControlName="receiptQuote"
          [p-label]="literals?.str0069 + '*'"
          [p-min-date]="minReceiptDate"
          p-required="true"
          (p-change-model)="sendStatusForm()">
        </po-datepicker>
      </div>
      <span class="po-icon po-icon-exclamation" [p-tooltip]="literals.str0146 + ' ' + literals.str0142"></span>
    </div>

    <po-divider></po-divider>

    <h2>{{ literals.str0071 }}</h2>

    <div class="po-md-11">
      <po-textarea
      class="text-area"
      name="deliveryplace"
      p-readonly="false"
      [(ngModel)]="deliveryaddress"
      [ngModelOptions]="{standalone: true}"
      (p-change)="changeAddress( $event)"
      (p-change-model)="sendStatusForm()"
      formControlName="fisicalplacedelivery"
      >
    </po-textarea>
    </div>
  </form>
</po-widget>

<po-modal #invalidItemsModal
  p-click-out="false"
  p-size="auto"
  [p-title]="literals?.str0222"
  [p-primary-action]="primaryActionInvalidItemsModal">

  <po-table
    p-hide-table-search="true"
    p-hide-text-overflow="true"
    [p-auto-collapse]="true"
    [p-columns]="columnsInvalidItemsGrid"
    [p-items]="invalidItems"
    [p-sort]="true"
    [p-striped]="true">
  </po-table>

</po-modal>
