<div *ngIf="loading">
  <po-loading-overlay></po-loading-overlay>
</div>
<po-page-default p-title="{{titlePage}}" [p-breadcrumb]="breadcrumb">
  
  <!-- componente amarração de produdo -->
  <pgc-supplier-binding 
    *ngIf="!suppliersPageViewer"
    [items]="quotationItems.items"
    (selectionStatus)="selectionStatus($event)"
  ></pgc-supplier-binding>

  <section *ngIf="suppliersPageViewer">

    <div class="po-row po-mb-5" *ngIf="divArea">
      <!-- Botão novo fornecedor -->
      <div class="po-mb-4">
        <po-button 
          p-icon="po-icon po-icon-plus" 
          p-label="{{ literals.str0514 }}"
          (p-click)="newSupplierBtt()">
        </po-button>
      </div>

      <div class="po-xl po-lg po-md po-sm po-p-0 container">

        <po-container>
          <!-- Contador de Disclemers de fornecedores adicioandos a cotação -->
          <h2>{{ literals?.str0102 }}</h2>

          <po-disclaimer-group p-hide-remove-all="true" (p-remove)="removeDisclaimer($event)"
            [p-disclaimers]="disclaimers">
          </po-disclaimer-group>

          <div *ngIf="disclaimers && disclaimers.length === 0"
            class="box po-text-center sample-container-dashboard po-mt-2 po-mb-2">
            <p class="po-font-text-small">{{ literals?.str0103 }}</p>
          </div>

        </po-container>

    </div>

  </div>

  <div class="po-row po-mt-5">

    <po-tabs>
      <!-- Aba últimos fornecedores -->
      <po-tab [p-label]="literals?.str0106" (p-click)="changeStatus(1)">

        <div class="row">
          <p class="po-font-text-large">{{ literals?.str0104 }}</p>
        </div>

        <div class="po-row po-mt-2">

          <!-- Ordenação  -->
          <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-pl-0">
            <po-select name="order"
              [p-label]="literals?.str0079"
              [p-options]="order"
              [p-disabled]="lastSuppliersList && lastSuppliersList.length === 0"
              (p-change)="orderLastSuppliers($event)">
            </po-select>
          </div>
          
          <!-- Campo de Busca  -->
          <div class="po-xl-5 po-lg-5 po-md-5 po-sm-5 po-mt-3" [formGroup]="searchForm">
            <po-input p-auto-focus="true" p-icon="po-icon po-icon-search" formControlName="searchValue"
              [p-placeholder]="literals?.str0108">
            </po-input>
          </div>

        </div>

        <div *ngIf="loading">
          <po-loading-overlay></po-loading-overlay>
        </div>

        <div class="po-row po-mt-2 po-mb-2" *ngIf="lastSuppliersList && lastSuppliersList.length > 0">
          <p class="po-font-text-small">
            {{ literals?.str0109 }} {{ lastSuppliersList?.length }} {{ literals?.str0110 }} {{ totalLastSuppliers }} {{ literals?.str0112 }}
          </p>
        </div>

        <div class="po-xl po-lg po-md po-sm po-p-0 po-mb-2 container" *ngFor="let supplier of lastSuppliersList">

          <po-container *ngIf="lastSuppliersList.length > 0">

            <div class="po-row">
              <po-checkbox name="checkbox" [p-label]="supplier?.companyname"
                (p-change)="selectSupplier($event, supplier)">
              </po-checkbox>
              {{ checkbox }}
            </div>

            <div class="po-row po-mt-2">

              <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0113" [p-value]="supplier?.suppliercode || literals?.str0122">
                </po-info>
              </div>

              <div class="po-xl-1 po-lg-1 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0114" [p-value]="supplier?.store || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-3 po-lg-3 po-md-2 po-sm-2 po-p-0">
                <po-info *ngIf="supplier.type === 'J' || supplier.type === 'X' || supplier.type === ''"
                  [p-label]="literals?.str0072" [p-value]="supplier?.cnpj || literals?.str0122"></po-info>
                <po-info *ngIf="supplier.type === 'F'" [p-label]="literals?.str0130"
                  [p-value]="supplier?.cnpj || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-3 po-lg-3 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0115" [p-value]="supplier?.email || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-1 po-lg-1 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0175" [p-value]="supplier?.areacode || '-'"></po-info>
              </div>

              <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0116" [p-value]="supplier?.telephone || literals?.str0122"></po-info>
              </div>

            </div>

            <div class="po-row po-mt-2 po-p-0">
              <po-button
                class="po-sm-6"
                [p-label]="literals?.str0123"
                (p-click)="showDetails(supplier)">
              </po-button>
              <po-button
                class="po-sm-6"
                [p-icon]="icon"
                [p-label]="literals?.str0124"
                (p-click)="openModalUpdateContacts(supplier)">
              </po-button>
            </div>

          </po-container>

        </div>

        <div *ngIf="lastSuppliersList && lastSuppliersList.length === 0"
          class="box po-text-center sample-container-dashboard po-mt-2 po-mb-2">
          <p class="po-font-text-large">{{ literals?.str0126 }}</p>
        </div>

        <div class="po-row">
          <po-button class="po-md-3 button-load"
            [p-disabled]="disableButtonLastSuppliers"
            [p-label]="literals.str0131"
            [p-loading]="loading"
            (p-click)="loadButton()">
          </po-button>
        </div>

      </po-tab>
      
      <!-- Aba todos os fornecedores  -->
      <po-tab p-active="true" [p-label]="literals?.str0107" (p-click)="changeStatus(0)">

        <div class="po-row po-mt-2">
          <p class="po-font-text-large">{{ literals?.str0138 }}</p>
        </div>

        <div class="po-row po-mt-2">

          <!-- Ordenação -->
          <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-pl-0">
            <po-select name="order"
              [p-label]="literals?.str0079"
              [p-options]="order"
              [p-disabled]="allSuppliersList && allSuppliersList.length === 0"
              (p-change)="orderAllSuppliers($event)">
            </po-select>
          </div>

          <!-- Campo de busca -->
          <div class="po-xl-5 po-lg-5 po-md-5 po-sm-5 po-mt-3" [formGroup]="searchForm">
            <po-input p-auto-focus="true" p-icon="po-icon po-icon-search" formControlName="searchValue"
              [p-placeholder]="literals?.str0108">
            </po-input>
          </div>

        </div>

        <div class="po-row po-mt-2 po-mb-2" *ngIf="allSuppliersList && allSuppliersList.length > 0">
          <p class="po-font-text-small">
            {{ literals?.str0109 }} {{ allSuppliersList?.length }} {{ literals?.str0110}} {{ totalSuppliers}} {{ literals?.str0112 }}
          </p>
        </div>

        <div class="po-xl po-lg po-md po-sm po-p-0 po-mb-2 container" *ngFor="let supplier of allSuppliersList">

          <po-container *ngIf="allSuppliersList.length > 0">
            <!-- <pre>{{ selectedSuppliers | json }}</pre> -->
            <div class="po-row">

              <po-checkbox name="checkbox" [p-label]="supplier?.companyname"
                (p-change)="selectSupplier($event, supplier)">
              </po-checkbox>
              {{ checkbox }}

            </div>

            <div class="po-row po-mt-2">

              <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0113" [p-value]="supplier?.suppliercode || literals?.str0122">
                </po-info>
              </div>

              <div class="po-xl-1 po-lg-1 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0114" [p-value]="supplier?.store || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-3 po-lg-3 po-md-2 po-sm-2 po-p-0">
                <po-info *ngIf="supplier.type === 'J' || supplier.type === 'X' || supplier.type === ''"
                  [p-label]="literals?.str0072" [p-value]="supplier?.cnpj || literals?.str0122"></po-info>
                <po-info *ngIf="supplier.type === 'F'" [p-label]="literals?.str0130"
                  [p-value]="supplier?.cnpj || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-3 po-lg-3 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0115" [p-value]="supplier?.email || literals?.str0122"></po-info>
              </div>

              <div class="po-xl-1 po-lg-1 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0175" [p-value]="supplier?.areacode || '-'"></po-info>
              </div>

              <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2 po-p-0">
                <po-info [p-label]="literals?.str0116" [p-value]="supplier?.telephone || literals?.str0122"></po-info>
              </div>

            </div>

            <div class="po-row po-mt-2 po-p-0">
              <po-button
                class="po-sm-6"
                [p-label]="literals?.str0123"
                (p-click)="showDetails(supplier)">
              </po-button>
              <po-button
                class="po-sm-6"
                [p-icon]="icon"
                [p-label]="literals?.str0124"
                (p-click)="openModalUpdateContacts(supplier)">
              </po-button>
            </div>
          </po-container>

        </div>

        <div *ngIf="allSuppliersList && allSuppliersList.length === 0"
          class="box po-text-center sample-container-dashboard po-mt-2 po-mb-2">
          <p class="po-font-text-large">{{ literals?.str0126 }}</p>
        </div>

        <div class="po-row">
          <po-button class="po-md-3 button-load"
            [p-disabled]="disableButtonAllSuppliers"
            [p-label]="literals.str0131"
            [p-loading]="loading"
            (p-click)="loadButton()">
          </po-button>
        </div>

      </po-tab>

    </po-tabs>

  </div>
</section>

<div class="buttonsSubmit po-mt-2">
  <po-button
    class="po-mr-md-2"
    [p-label]="this.literals?.str0101"
    (p-click)="cancel()">
  </po-button>
  <po-button
    p-kind="primary"
    [p-label]="this.literals?.str0141"
    [p-disabled]="!isValidSuppliers"
    (p-click)="generateQuotation()">
  </po-button>
</div>

</po-page-default>

<!-- page novo fornecedor participante -->
<po-page-slide p-size="sm" #newSupplierPage p-title="{{ literals.str0514 }}"> 
  <p class="po-font-text"> {{ literals.str0464 }} </p>
  <p class="po-font-text po-font-text-bold"> {{ literals.str0465 }} </p>

  <form [formGroup]="newSuppliersForm">

   <div class="po-mt-4 new-supplier"> 
    <div class="po-mb-2">
      <po-input p-required="true" 
        p-upper-case="true"
        [(ngModel)]="name"
        formControlName="companyname" 
        p-label="{{ literals.str0073 }}"
        [p-maxlength]="corporateNameFieldSize"> 
      </po-input>
    </div>

    <po-input
      formControlName="email"
      [(ngModel)]="email"
      p-label="{{ literals.str0313 }}"
      [p-no-autocomplete]="true"
      [p-maxlength]="emailParticipantFieldSize">
    </po-input>
    </div>

    <div class="po-row buttons">
      <po-button class="button" [p-label]="literals?.str0101" (p-click)="newSupplierPage.close()"> </po-button>
      <po-button class="button" [p-disabled]="newSuppliersForm.invalid" p-label="{{ literals.str0466 }}" p-kind="primary" (p-click)="addSupplier()">
      </po-button>
    </div>
  </form>

 </po-page-slide>

<po-modal #supplierDetailsModal
  p-size="auto"
  p-click-out="false"
  [p-title]="literals?.str0125"
  [p-primary-action]="primaryActionSupplierDetailsModal">
  <po-container>

    <po-info class="po-md-4" [p-label]="literals?.str0073" [p-value]="supplierDetailsToBeShown?.companyname || literals?.str0122"> </po-info>
    <po-info class="po-md-4" [p-label]="literals?.str0156" [p-value]="supplierDetailsToBeShown?.fantasyname || literals?.str0122"> </po-info>

    <po-divider class="po-md-12" ></po-divider>

      <po-info class="po-md-4" [p-label]="literals?.str0153" [p-value]="supplierDetailsToBeShown?.address || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0154" [p-value]="supplierDetailsToBeShown?.district || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0157" [p-value]="supplierDetailsToBeShown?.county || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0158" [p-value]="supplierDetailsToBeShown?.state || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0155" [p-value]="supplierDetailsToBeShown?.zipcode || literals?.str0122"> </po-info>

    <po-divider class="po-md-12" ></po-divider>

      <po-info class="po-md-4" [p-label]="literals?.str0175" [p-value]="supplierDetailsToBeShown?.areacode || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0116" [p-value]="supplierDetailsToBeShown?.telephone || literals?.str0122"> </po-info>
      <po-info class="po-md-4" [p-label]="literals?.str0115" [p-value]="supplierDetailsToBeShown?.email || literals?.str0122"> </po-info>

  </po-container>
</po-modal>

<div *ngIf="loadingGenerateQuotationModal">
  <po-loading-overlay></po-loading-overlay>
</div>

<pgc-supplier-email #supplierEmailModal
  [modalTitle]="literals?.str0204 + ' ' + quotationNumber + ' ' + literals?.str0291"
  [quotationNumber]="quotationNumber"
  [supplierProposal]="supplierProposal"
  [isSupplierSelection]="true"
  [sendPurchaserWf]="sendPurchaserWf"
>
</pgc-supplier-email>

<po-modal #supplierUpdateModal
  p-click-out="false"
  p-size="auto"
  [p-title]="literals?.str0124"
  [p-primary-action]="primaryActionUpdateSupplierModal"
  [p-secondary-action]="secondaryActionUpdateSupplierModal">
  <div *ngIf="loadingModalUpdateSupplier">
    <po-loading-overlay></po-loading-overlay>
  </div>

  <form [formGroup]="supplierForm">
    <div class="row">
      <div class="po-xl-2 po-lg-2 ">
        <po-input
          p-auto-focus="true"
          formControlName="areacode"
          p-mask="999"
          [p-label]="literals?.str0175"
          [p-placeholder]="literals?.str0175"
          [p-maxlength]="areaCodeFieldSize">
        </po-input>
      </div>
      <div class="po-xl-4 po-lg-4 ">
        <po-input
            p-auto-focus="true"
            formControlName="telephone"
            [p-label]="literals?.str0116"
            [p-placeholder]="literals?.str0116"
            [p-maxlength]="telephoneFieldSize">
          </po-input>
      </div>
      <div class="po-xl-6 po-lg-6">
        <po-input
          formControlName="email"
          [p-label]="literals?.str0115"
          [p-placeholder]="literals?.str0115"
          [p-maxlength]="emailFieldSize">
        </po-input>
      </div>
    </div>

  </form>

</po-modal>

<po-modal #removeAppendModal
  [p-title]="literals?.str0175"
  [p-primary-action]="confirmRemoveAppend"
  [p-secondary-action]="closeRemoveAppendModalAction">
  {{ literals?.str0174 }}
</po-modal>

<po-modal #invalidItemsModal
  p-size="auto"
  [p-title]="literals?.str0222"
  p-hide-close="true"
  [p-primary-action]="confirmInvalidItemsModalAction"
  [p-secondary-action]="closeInvalidItemsModalAction">

    <po-table
      p-hide-table-search="true"
      [p-hide-text-overflow] = "true"
      [p-columns]="columsPurchaseRequestsItemsTable"
      [p-hide-columns-manager]="true"
      [p-items]="purchaseRequestsInvalidItems"
      [p-sort]="true"
      [p-striped]="true">
    </po-table>

    <tgc-form-message [text]="literals.str0225"></tgc-form-message>
    <font class="po-font-text-large-bold po-mt-2"> {{literals.str0226}}</font>

</po-modal>

<po-modal #OnlyInvalidItemsModal
  p-click-out="false"
  p-size="auto"
  p-hide-close="true"
  [p-title]="literals?.str0222"
  [p-primary-action]="closeOnlyInvalidItemsModalAction"
  >

  <po-table
    p-hide-table-search="true"
    [p-hide-text-overflow]="true"
    [p-columns]="columsPurchaseRequestsItemsTable"
    [p-hide-columns-manager]="true"
    [p-items]="purchaseRequestsInvalidItems"
    [p-sort]="true"
    [p-striped]="true">
  </po-table>

  <tgc-form-message [text]="literals?.str0231"></tgc-form-message>

</po-modal>
