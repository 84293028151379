/* src/app/features/purchase-requests/purchase-requests-list/purchase-requests-list.component.css */
.search {
  margin-top: 24px;
  margin-left: 16px;
}
.buttons {
  display: flex;
  margin-top: 50px;
}
.buttons > po-button {
  margin-right: 8px;
}
.wrapper {
  height: 40px;
  margin-top: 4x;
  padding: 4px;
  margin-bottom: 1rem;
}
.cart {
  cursor: pointer;
}
.btn-quote {
  float: right;
  margin: 5px 5px 0 0;
}
.warning {
  color: #be3e37;
}
.alertForm {
  margin-bottom: 20px;
}
/*# sourceMappingURL=purchase-requests-list.component.css.map */
