
<po-page-default [p-title]="literals?.str0637.toLocaleUpperCase()" [p-actions]="actions">
      
    <div *ngIf="loading">
        <po-loading-overlay p-screen-lock="true">
        </po-loading-overlay>
    </div>

    <div class="po-md-6">
        <div class="head">
            <!-- formulario de busca -->
            <tgc-search 
                (inputDateFilter)="handleChangeInputDateFilter($event)"
                (inputSearchFilter)="handleInputSearchFilter($event)"
                (inputAdvancedFilter)="handleAdvancedFilterForm($event)"
                (changeInputAdvancedFilter)="handleChangeInputAdvancedFilter($event)"
                (removeAllFilters)="handleRemoveAllFilters()"
                urlParam="open">
            </tgc-search>
        
            <div class="records" *ngIf="openItems1?.length">
                <p class="po-font-text-small">
                    {{ literals?.str0109 + ' ' + openItems1?.length + ' ' + literals?.str0110 + ' ' + openTotal + ' ' + literals?.str0059 }}
                </p>
            </div>    
        
            <div class="po-font-text-small-bold"> {{ literals?.str0640}} <span class="po-icon po-icon-exclamation" [p-tooltip]="literals.str0644"></span></div>
        </div>
        <po-table
            #TableAllItems
            p-height="450"
            p-infinite-scroll="true"
            p-infinite-scroll-distance="80"
            p-sort="true"
            p-hide-text-overflow="true"
            [p-hide-columns-manager]="false"
            [p-columns]="columns"
            [p-items]="openItems1"
            [p-striped]="true"
            [p-loading]="loading"
            [p-selectable]="true"
            p-spacing="small"
            [p-show-more-disabled]="!hasMoreOpenItems"
            (p-all-selected)="handleSelectAllItems($event)"
            (p-all-unselected)="handleUnselectAllItems($event)"
            (p-show-more)="getMoreOpenItems()"        
            (p-selected)="handleSelectItem($event)"
            (p-unselected)="inputAnotherTable($event, 'delete')">
        </po-table>
    </div>
        
    <div class="po-md-6">
        <div class="head">
            <div class="po-row">
                <po-input class="po-sm-6 po-md-6 po-lg-6 po-xl-5" name="DescriptionBranch" [(ngModel)]="branchCode" p-clean [p-label]="literals?.str0638" p-readonly="true"> </po-input>     
                <po-info p-label="Atenção"  p-value="{{literals?.str0643}}"> </po-info>
            </div>
            <p class="po-font-text-small-bold"> {{ literals?.str0641}}  </p>   
        </div>
        <po-table
            #TableSelectedItems
            p-height="450"
            p-infinite-scroll="true"
            p-infinite-scroll-distance="80"
            p-sort="true"
            p-hide-text-overflow="true"
            [p-hide-columns-manager]="false"
            [p-columns]="columns"
            [p-items]="itemsSelectedFork"
            [p-striped]="true"
            [p-hide-action-fixed-columns]="true">
        </po-table>
    </div>

 
    <div class="wrapper" *ngIf="openItems1?.length > 0 " >

    <!-- Carrinho -->
    <po-tag *ngIf="quotationItems1?.length > 0" class="cart" p-color="color-08" p-icon="po-icon po-icon-cart"
        p-value="{{ this.quotationItems1?.length === 1 ? this.literals?.str0087 : this.literals?.str0088 }} {{ this.quotationItems1?.length }}"
        (click)="handleOpenModalItemsSelected()">
    </po-tag>

    <!-- Botãpo centralizar -->
    <po-button class="po-pull-right" p-type="primary" [p-label]="literals?.str0646" [p-loading]="loading"
        [p-disabled]="quotationItems1.length === 0 || loading" (p-click)="startCentralize()">
    </po-button>
    </div>
            
    <!-- Modal Itens selecionados/Carrinho de compras -->
    <po-modal #itemsSelectedModal
    p-click-out="false"
    p-size="auto"
    [p-title]="literals.str0151"
    [p-primary-action]="primaryActionItemsSelectedModal">
    
    <po-table
        [p-loading]="loadingCart" 
        p-height="500"
        p-sort="true"
        p-hide-text-overflow="true"
        [p-columns]="itemsSelectedColumns1"
        [p-items]="itemsSelected1"
        [p-striped]="true"
        [p-selectable]="false">
    </po-table>
    
    </po-modal>
    
    <!--Modal listagem de anexos-->
    <po-modal #appendModal
    p-click-out="true"
    p-size="lg"
    [p-title]="literals?.str0046"
    [p-primary-action]="primaryActionAppendModal">
    <div *ngIf="downloadLoading">
        <po-loading-overlay
        p-screen-lock="true"
        ></po-loading-overlay>
    </div>
    <po-table
        p-height= "300"
        p-infinite-scroll="true"
        p-infinite-scroll-distance="80"
        p-sort="true"
        p-hide-text-overflow="true"
        [p-columns]="appendColumns"
        [p-items]="appendItems1?.items"
        [p-striped]="true"
        [p-selectable]="false"
        [p-loading]="isLoadingAppendTable"
        [p-show-more-disabled]="!hasMoreAppends"
        (p-show-more)="getMoreAppends()">
    </po-table>
    
    </po-modal>
    
    <po-modal #invalidItemsModal
    p-click-out="false"
    p-size="auto"
    [p-title]="literals?.str0222"
    [p-primary-action]="primaryActionInvalidItemsModal"
    [p-secondary-action]="SecundaryActionInvalidItemsModal"
    >
    
    <po-table
        [p-hide-text-overflow]="true"
        [p-auto-collapse]="true"
        [p-columns]="columnsInvalidItemsGrid"
        [p-items]="invalidItems"
        [p-sort]="true"
        [p-striped]="true">
    </po-table>
    
    <tgc-form-message [text]="literals.str0225"></tgc-form-message>
    <font class="po-font-text-large-bold po-mt-2">{{ literals.str0226 }}</font>
    
    </po-modal>
    
    <po-modal #OnlyInvalidItemsModal
    p-click-out="false"
    p-size="auto"
    [p-title]="literals?.str0222"
    [p-primary-action]="primaryOnlyInvalidItemsModal"
    >
    
    <po-table
        [p-hide-text-overflow]="true"
        [p-auto-collapse]="true"
        [p-columns]="columnsInvalidItemsGrid"
        [p-items]="invalidItems"
        [p-sort]="true"
        [p-striped]="true">
    </po-table>
    
    <tgc-form-message [text]="literals?.str0224"></tgc-form-message>
    
    </po-modal>
      
  </po-page-default>
