/* src/app/features/purchase-requests/shared/components/search/search.component.css */
.search-date {
  width: 238px;
}
.search-lookups {
  display: flex;
}
.lookup-input {
  width: 260px;
}
.buttons {
  justify-content: flex-end;
  margin-top: 50px;
}
.buttons > po-button {
  margin-right: 8px;
}
.exclamation {
  margin-top: 23px;
}
.switch {
  margin-top: 20px;
}
/*# sourceMappingURL=search.component.css.map */
